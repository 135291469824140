import React, { useEffect } from "react";
import AdminLayout from "./AdminLayout";
import { useForm } from "react-hook-form";
import { Form, TextArea, Label, Button, Tab } from "semantic-ui-react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { eventAndFeedbackGraphqlClient } from "..";

export type FeedbackQuestions = {
  positiveCommentDescription: string;
  improveCommentDescription: string;
  leadTrainerQuestion: string;
  coLeadTrainerQuestion: string;
  hostQuestion: string;
  eventQuestion: string;
  feedbackIntroduction: string;
  feedbackTitle: string;
  feedbackTrainerSectionTitle: string;
  feedbackWorkshopSectionTitle: string;
  feedbackOrgSectionTitle: string;
  personalDataDescription: string;
  newsletterDescription: string;
  showLastNameDescription: string;
  thankYouForFeedback: string;
  youWillReceiveAMailForYourFeedback: string;
  errorSubmittingFeedback: string;
  errorRequiredFieldSubfix: string;
  errorMissingField: string;
  submitYourFeedback: string;
  firstName: string;
  lastName: string;
  city: string;
  country: string;
  email: string;
  organization: string;
};

export type LocalizedValue = { locale: string; localizedValue: string };

export type FeedbackQuestionsLocalized = {
  positiveCommentDescription: LocalizedValue[];
  improveCommentDescription: LocalizedValue[];
  leadTrainerQuestion: LocalizedValue[];
  coLeadTrainerQuestion: LocalizedValue[];
  hostQuestion: LocalizedValue[];
  eventQuestion: LocalizedValue[];
  feedbackIntroduction: LocalizedValue[];
  feedbackTitle: LocalizedValue[];
  feedbackTrainerSectionTitle: LocalizedValue[];
  feedbackWorkshopSectionTitle: LocalizedValue[];
  feedbackOrgSectionTitle: LocalizedValue[];
  personalDataDescription: LocalizedValue[];
  newsletterDescription: LocalizedValue[];
  showLastNameDescription: LocalizedValue[];
  thankYouForFeedback: LocalizedValue[];
  youWillReceiveAMailForYourFeedback: LocalizedValue[];
  errorSubmittingFeedback: LocalizedValue[];
  errorRequiredFieldSubfix: LocalizedValue[];
  errorMissingField: LocalizedValue[];
  submitYourFeedback: LocalizedValue[];
  firstName: LocalizedValue[];
  lastName: LocalizedValue[];
  city: LocalizedValue[];
  country: LocalizedValue[];
  email: LocalizedValue[];
  organization: LocalizedValue[];
};

export function useFeedbackQuestions() {
  const { loading, error, data } = useQuery<{
    feedbackQuestions: FeedbackQuestionsLocalized;
  }>(
    gql`
      query FeedbackQuestions {
        feedbackQuestions {
          positiveCommentDescription {
            locale
            localizedValue
          }
          improveCommentDescription {
            locale
            localizedValue
          }
          leadTrainerQuestion {
            locale
            localizedValue
          }
          coLeadTrainerQuestion {
            locale
            localizedValue
          }
          hostQuestion {
            locale
            localizedValue
          }
          eventQuestion {
            locale
            localizedValue
          }
          feedbackIntroduction {
            locale
            localizedValue
          }
          feedbackTitle {
            locale
            localizedValue
          }
          feedbackTrainerSectionTitle {
            locale
            localizedValue
          }
          feedbackWorkshopSectionTitle {
            locale
            localizedValue
          }
          feedbackOrgSectionTitle {
            locale
            localizedValue
          }
          personalDataDescription {
            locale
            localizedValue
          }
          newsletterDescription {
            locale
            localizedValue
          }
          showLastNameDescription {
            locale
            localizedValue
          }
          thankYouForFeedback {
            locale
            localizedValue
          }
          youWillReceiveAMailForYourFeedback {
            locale
            localizedValue
          }
          errorSubmittingFeedback {
            locale
            localizedValue
          }
          errorRequiredFieldSubfix {
            locale
            localizedValue
          }
          errorMissingField {
            locale
            localizedValue
          }
          submitYourFeedback {
            locale
            localizedValue
          }
          firstName {
            locale
            localizedValue
          }
          lastName {
            locale
            localizedValue
          }
          city {
            locale
            localizedValue
          }
          country {
            locale
            localizedValue
          }
          email {
            locale
            localizedValue
          }
          organization {
            locale
            localizedValue
          }
        }
      }
    `,
    { client: eventAndFeedbackGraphqlClient }
  );

  return { loading, error, feedbackQuestions: data?.feedbackQuestions };
}

export function useLocalizedFeedbackQuestions() {
  const {
    loading,
    error,
    feedbackQuestions: feedbackQuestionsLocalized,
  } = useFeedbackQuestions();

  const currentLocale = localStorage.getItem("selectedLang");

  delete (feedbackQuestionsLocalized as any)?.__typename;

  const feedbackQuestions: FeedbackQuestions = Object.entries(
    feedbackQuestionsLocalized || {}
  )
    .map(([questionId, localizedQuestion]) => {
      if (currentLocale) {
        return {
          questionId,
          value:
            localizedQuestion.find(
              (localizd) => localizd.locale === currentLocale
            )?.localizedValue ||
            localizedQuestion.find((localizd) => localizd.locale === "default")
              ?.localizedValue,
        };
      } else {
        return {
          questionId,
          value: localizedQuestion.find(
            (localizd) => localizd.locale === "default"
          )?.localizedValue,
        };
      }
    })
    .reduce(
      (previous, current) => ({
        ...previous,
        [current.questionId]: current.value,
      }),
      {} as FeedbackQuestions
    );

  return { loading, error, feedbackQuestions };
}

export default function AdminFeedbackQuestions() {
  const { loading, error, feedbackQuestions } = useFeedbackQuestions();

  const { data: locales } = useQuery<{ languages: { name: string }[] }>(gql`
    query Locales {
      languages {
        name
      }
    }
  `);

  const languages = [
    "default",
    ...(locales?.languages || []).map((lang) => lang.name),
  ];

  const { register, handleSubmit, setValue, watch } = useForm();

  const positiveCommentDescription = watch(
    "positiveCommentDescription"
  ) as LocalizedValue[];
  const improveCommentDescription = watch(
    "improveCommentDescription"
  ) as LocalizedValue[];
  const leadTrainerQuestion = watch("leadTrainerQuestion") as LocalizedValue[];
  const coLeadTrainerQuestion = watch(
    "coLeadTrainerQuestion"
  ) as LocalizedValue[];
  const hostQuestion = watch("hostQuestion") as LocalizedValue[];
  const eventQuestion = watch("eventQuestion") as LocalizedValue[];
  const feedbackIntroduction = watch(
    "feedbackIntroduction"
  ) as LocalizedValue[];
  const feedbackTitle = watch("feedbackTitle") as LocalizedValue[];
  const feedbackTrainerSectionTitle = watch(
    "feedbackTrainerSectionTitle"
  ) as LocalizedValue[];
  const feedbackWorkshopSectionTitle = watch(
    "feedbackWorkshopSectionTitle"
  ) as LocalizedValue[];
  const feedbackOrgSectionTitle = watch(
    "feedbackOrgSectionTitle"
  ) as LocalizedValue[];
  const personalDataDescription = watch(
    "personalDataDescription"
  ) as LocalizedValue[];
  const newsletterDescription = watch(
    "newsletterDescription"
  ) as LocalizedValue[];
  const showLastNameDescription = watch(
    "showLastNameDescription"
  ) as LocalizedValue[];
  const thankYouForFeedback = watch("thankYouForFeedback") as LocalizedValue[];
  const youWillReceiveAMailForYourFeedback = watch(
    "youWillReceiveAMailForYourFeedback"
  ) as LocalizedValue[];
  const errorSubmittingFeedback = watch(
    "errorSubmittingFeedback"
  ) as LocalizedValue[];
  const errorRequiredFieldSubfix = watch(
    "errorRequiredFieldSubfix"
  ) as LocalizedValue[];
  const errorMissingField = watch("errorMissingField") as LocalizedValue[];
  const submitYourFeedback = watch("submitYourFeedback") as LocalizedValue[];
  const firstName = watch("firstName") as LocalizedValue[];
  const lastName = watch("lastName") as LocalizedValue[];
  const city = watch("city") as LocalizedValue[];
  const country = watch("country") as LocalizedValue[];
  const email = watch("email") as LocalizedValue[];
  const organization = watch("organization") as LocalizedValue[];

  useEffect(() => {
    register({ name: "positiveCommentDescription" });
    register({ name: "improveCommentDescription" });
    register({ name: "leadTrainerQuestion" });
    register({ name: "coLeadTrainerQuestion" });
    register({ name: "hostQuestion" });
    register({ name: "eventQuestion" });
    register({ name: "feedbackIntroduction" });
    register({ name: "feedbackTitle" });
    register({ name: "feedbackTrainerSectionTitle" });
    register({ name: "feedbackWorkshopSectionTitle" });
    register({ name: "feedbackOrgSectionTitle" });
    register({ name: "personalDataDescription" });
    register({ name: "newsletterDescription" });
    register({ name: "showLastNameDescription" });
    register({ name: "thankYouForFeedback" });
    register({ name: "youWillReceiveAMailForYourFeedback" });
    register({ name: "errorSubmittingFeedback" });
    register({ name: "errorRequiredFieldSubfix" });
    register({ name: "errorMissingField" });
    register({ name: "submitYourFeedback" });
    register({ name: "firstName" });
    register({ name: "lastName" });
    register({ name: "city" });
    register({ name: "country" });
    register({ name: "email" });
    register({ name: "organization" });
  }, [register]);

  useEffect(() => {
    if (feedbackQuestions) {
      setValue(
        "positiveCommentDescription",
        feedbackQuestions.positiveCommentDescription
      );
      setValue(
        "improveCommentDescription",
        feedbackQuestions.improveCommentDescription
      );
      setValue("leadTrainerQuestion", feedbackQuestions.leadTrainerQuestion);
      setValue(
        "coLeadTrainerQuestion",
        feedbackQuestions.coLeadTrainerQuestion
      );
      setValue("hostQuestion", feedbackQuestions.hostQuestion);
      setValue("eventQuestion", feedbackQuestions.eventQuestion);
      setValue("feedbackIntroduction", feedbackQuestions.feedbackIntroduction);
      setValue("feedbackTitle", feedbackQuestions.feedbackTitle);
      setValue(
        "feedbackTrainerSectionTitle",
        feedbackQuestions.feedbackTrainerSectionTitle
      );
      setValue(
        "feedbackWorkshopSectionTitle",
        feedbackQuestions.feedbackWorkshopSectionTitle
      );
      setValue(
        "feedbackOrgSectionTitle",
        feedbackQuestions.feedbackOrgSectionTitle
      );
      setValue(
        "personalDataDescription",
        feedbackQuestions.personalDataDescription
      );
      setValue(
        "newsletterDescription",
        feedbackQuestions.newsletterDescription
      );
      setValue(
        "showLastNameDescription",
        feedbackQuestions.showLastNameDescription
      );
      setValue("thankYouForFeedback", feedbackQuestions.thankYouForFeedback);
      setValue(
        "youWillReceiveAMailForYourFeedback",
        feedbackQuestions.youWillReceiveAMailForYourFeedback
      );
      setValue(
        "errorSubmittingFeedback",
        feedbackQuestions.errorSubmittingFeedback
      );
      setValue(
        "errorRequiredFieldSubfix",
        feedbackQuestions.errorRequiredFieldSubfix
      );
      setValue("errorMissingField", feedbackQuestions.errorMissingField);
      setValue("submitYourFeedback", feedbackQuestions.submitYourFeedback);
      setValue("firstName", feedbackQuestions.firstName);
      setValue("lastName", feedbackQuestions.lastName);
      setValue("city", feedbackQuestions.city);
      setValue("country", feedbackQuestions.country);
      setValue("email", feedbackQuestions.email);
      setValue("organization", feedbackQuestions.organization);
    }
  }, [feedbackQuestions, setValue]);

  const [updateFeedbackQuestions] = useMutation(
    gql`
      mutation AdminFeedbackQuestions(
        $feedbackQuestions: FeedbackQuestionsInput
      ) {
        adminFeedbackQuestions(feedbackQuestions: $feedbackQuestions) {
          positiveCommentDescription {
            locale
            localizedValue
          }
          improveCommentDescription {
            locale
            localizedValue
          }
          leadTrainerQuestion {
            locale
            localizedValue
          }
          coLeadTrainerQuestion {
            locale
            localizedValue
          }
          hostQuestion {
            locale
            localizedValue
          }
          eventQuestion {
            locale
            localizedValue
          }
          feedbackIntroduction {
            locale
            localizedValue
          }
          feedbackTitle {
            locale
            localizedValue
          }
          feedbackTrainerSectionTitle {
            locale
            localizedValue
          }
          feedbackWorkshopSectionTitle {
            locale
            localizedValue
          }
          feedbackOrgSectionTitle {
            locale
            localizedValue
          }
          personalDataDescription {
            locale
            localizedValue
          }
          newsletterDescription {
            locale
            localizedValue
          }
          showLastNameDescription {
            locale
            localizedValue
          }
          thankYouForFeedback {
            locale
            localizedValue
          }
          youWillReceiveAMailForYourFeedback {
            locale
            localizedValue
          }
          errorSubmittingFeedback {
            locale
            localizedValue
          }
          errorRequiredFieldSubfix {
            locale
            localizedValue
          }
          errorMissingField {
            locale
            localizedValue
          }
          submitYourFeedback {
            locale
            localizedValue
          }
          firstName {
            locale
            localizedValue
          }
          lastName {
            locale
            localizedValue
          }
          city {
            locale
            localizedValue
          }
          country {
            locale
            localizedValue
          }
          email {
            locale
            localizedValue
          }
          organization {
            locale
            localizedValue
          }
        }
      }
    `,
    { client: eventAndFeedbackGraphqlClient }
  );

  return (
    <AdminLayout>
      {loading && <>Loading...</>}
      {error && <>An error occured, please reload the page in few minutes</>}
      <h3>
        You can use{" "}
        <Label style={{ verticalAlign: "middle" }} color="black" horizontal>
          <code>{`{}`}</code>
        </Label>{" "}
        notation to use a context variable.
      </h3>

      <p>Available variables :</p>
      <ul>
        <li>
          <Label style={{ verticalAlign: "middle" }} color="black" horizontal>
            <code>{`{leadTrainer.firstName}`}</code>
          </Label>{" "}
          lead trainer first name
        </li>
        <li>
          <Label style={{ verticalAlign: "middle" }} color="black" horizontal>
            <code>{`{leadTrainer.lastName}`}</code>
          </Label>{" "}
          lead trainer last name
        </li>
      </ul>
      <p>Additional variables only available for co lead question :</p>
      <ul>
        <li>
          <Label style={{ verticalAlign: "middle" }} color="black" horizontal>
            <code>{`{coLeadTrainer.firstName}`}</code>
          </Label>{" "}
          co-lead trainer first name
        </li>
        <li>
          <Label style={{ verticalAlign: "middle" }} color="black" horizontal>
            <code>{`{coLeadTrainer.lastName}`}</code>
          </Label>{" "}
          co-lead trainer last name
        </li>
      </ul>
      <Form
        onSubmit={handleSubmit((newFeedbackQuestions) => {
          updateFeedbackQuestions({
            variables: { feedbackQuestions: newFeedbackQuestions },
          }).then(() => alert("Successfully updated questions !"));
        })}
      >
        <LocalizedTextareaField
          languages={languages}
          fieldId={"feedbackTitle"}
          label={"Feedback title text"}
          value={feedbackTitle}
          setValue={setValue}
        />
        <LocalizedTextareaField
          languages={languages}
          fieldId={"feedbackTrainerSectionTitle"}
          label={"Feedback trainer section title"}
          value={feedbackTrainerSectionTitle}
          setValue={setValue}
        />
        <LocalizedTextareaField
          languages={languages}
          fieldId={"feedbackWorkshopSectionTitle"}
          label={"Feedback workshop section title"}
          value={feedbackWorkshopSectionTitle}
          setValue={setValue}
        />
        <LocalizedTextareaField
          languages={languages}
          fieldId={"feedbackOrgSectionTitle"}
          label={"Feedback organization section title"}
          value={feedbackOrgSectionTitle}
          setValue={setValue}
        />
        <LocalizedTextareaField
          languages={languages}
          fieldId={"feedbackIntroduction"}
          label={"Feedback introduction text"}
          value={feedbackIntroduction}
          setValue={setValue}
        />
        <LocalizedTextareaField
          languages={languages}
          fieldId={"positiveCommentDescription"}
          label={
            "What did you liked question (appliable to lead, co lead and host evaluation questions)"
          }
          value={positiveCommentDescription}
          setValue={setValue}
        />
        <LocalizedTextareaField
          languages={languages}
          fieldId={"improveCommentDescription"}
          label={
            "What to improve question (appliable to lead, co lead and host evaluation questions)"
          }
          value={improveCommentDescription}
          setValue={setValue}
        />
        <LocalizedTextareaField
          languages={languages}
          fieldId={"leadTrainerQuestion"}
          label={"Lead trainer recommendation/evaluation question"}
          value={leadTrainerQuestion}
          setValue={setValue}
        />
        <LocalizedTextareaField
          languages={languages}
          fieldId={"coLeadTrainerQuestion"}
          label={
            "Co-lead trainer recommendation/evaluation question, using at least coLeadTrainerFirstName variable as part of this question is recommended to give user context about whom is he commenting"
          }
          value={coLeadTrainerQuestion}
          setValue={setValue}
        />
        <LocalizedTextareaField
          languages={languages}
          fieldId={"eventQuestion"}
          label={"Event/Workshop recommendation/evaluation question"}
          value={eventQuestion}
          setValue={setValue}
        />
        <LocalizedTextareaField
          languages={languages}
          fieldId={"hostQuestion"}
          label={"Host recommendation/evaluation question"}
          value={hostQuestion}
          setValue={setValue}
        />
        <LocalizedTextareaField
          languages={languages}
          fieldId={"personalDataDescription"}
          label={"Text preceding personal data form"}
          value={personalDataDescription}
          setValue={setValue}
        />
        <LocalizedTextareaField
          languages={languages}
          fieldId={"firstName"}
          label={"firstName"}
          value={firstName}
          setValue={setValue}
        />
        <LocalizedTextareaField
          languages={languages}
          fieldId={"lastName"}
          label={"lastName"}
          value={lastName}
          setValue={setValue}
        />
        <LocalizedTextareaField
          languages={languages}
          fieldId={"city"}
          label={"city"}
          value={city}
          setValue={setValue}
        />
        <LocalizedTextareaField
          languages={languages}
          fieldId={"country"}
          label={"country"}
          value={country}
          setValue={setValue}
        />
        <LocalizedTextareaField
          languages={languages}
          fieldId={"email"}
          label={"email"}
          value={email}
          setValue={setValue}
        />
        <LocalizedTextareaField
          languages={languages}
          fieldId={"organization"}
          label={"organization"}
          value={organization}
          setValue={setValue}
        />
        <LocalizedTextareaField
          languages={languages}
          fieldId={"showLastNameDescription"}
          label={
            "Wheter to show lastName on dthinking academy or not checkbox label"
          }
          value={showLastNameDescription}
          setValue={setValue}
        />
        <LocalizedTextareaField
          languages={languages}
          fieldId={"newsletterDescription"}
          label={"Newsletter checkbox label"}
          value={newsletterDescription}
          setValue={setValue}
        />
        <LocalizedTextareaField
          languages={languages}
          fieldId={"submitYourFeedback"}
          label={"Submit button label"}
          value={submitYourFeedback}
          setValue={setValue}
        />
        <LocalizedTextareaField
          languages={languages}
          fieldId={"errorSubmittingFeedback"}
          label={"Error while submitting thhe feedback message"}
          value={errorSubmittingFeedback}
          setValue={setValue}
        />
        <LocalizedTextareaField
          languages={languages}
          fieldId={"errorRequiredFieldSubfix"}
          label={
            "Subfix to add to missing field label when submitting with those fields empty"
          }
          value={errorRequiredFieldSubfix}
          setValue={setValue}
        />
        <LocalizedTextareaField
          languages={languages}
          fieldId={"errorMissingField"}
          label={
            "Error message to display when there is at least one missing field"
          }
          value={errorMissingField}
          setValue={setValue}
        />

        <LocalizedTextareaField
          languages={languages}
          fieldId={"thankYouForFeedback"}
          label={"Thank you for your feedback message"}
          value={thankYouForFeedback}
          setValue={setValue}
        />
        <LocalizedTextareaField
          languages={languages}
          fieldId={"youWillReceiveAMailForYourFeedback"}
          label={"You will receive a mail to confirm your feedback message"}
          value={youWillReceiveAMailForYourFeedback}
          setValue={setValue}
        />
        <Button type="submit">Update questions</Button>
      </Form>
    </AdminLayout>
  );
}

export const LocalizedTextareaField = ({
  fieldId,
  value,
  label,
  setValue,
  languages,
}: {
  fieldId: string;
  label: string;
  value: LocalizedValue[];
  languages: string[];
  setValue: (id: string, value: LocalizedValue[]) => void;
}) => (
  <>
    <Form.Field>
      <label>{label}</label>

      <Tab
        menu={{
          attached: true,
          tabular: true,
          style: { maxWidth: "100%", overflowX: "auto", overflowY: "hidden" },
        }}
        panes={languages.map((lang) => {
          return {
            menuItem: lang,
            render: () => (
              <Tab.Pane>
                <TextArea
                  key={fieldId + lang}
                  onChange={(__, newState) => {
                    if (!value) {
                      value = [];
                    }
                    if (
                      !value.find(
                        (localizedValue) => localizedValue.locale === lang
                      )
                    ) {
                      value.push({
                        locale: lang,
                        localizedValue: newState.value as string,
                      });
                    }
                    setValue(
                      `${fieldId}`,
                      value.map((localizedValue) => {
                        if (localizedValue.locale === lang) {
                          return {
                            locale: localizedValue.locale,
                            localizedValue: newState.value as string,
                          };
                        }
                        return localizedValue;
                      })
                    );
                  }}
                  value={
                    (value || []).find(
                      (localizedValue) => localizedValue.locale === lang
                    )?.localizedValue
                  }
                />
              </Tab.Pane>
            ),
          };
        })}
      />
    </Form.Field>
  </>
);
