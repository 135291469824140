import FormValue from "../services/FormValue";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Placeholder, Input, Segment, Dropdown } from "semantic-ui-react";
import React, { useState } from "react";
import AdminLayout from "./AdminLayout";

export default function AdminFormValues({
  formValuePlural,
  formValueSingular,
  withSlug,
}: {
  formValueSingular: string;
  formValuePlural: string;
  withSlug?: boolean;
}) {
  const { loading, error, data, refetch } = useQuery(
    gql`
            query Get {
                ${formValuePlural} {
                    _id
                    name
                    ${withSlug ? "slug" : ""}
                }
            }
        `,
    {}
  );

  const [updateData, { data: updatedData }] = useMutation(
    gql`
            mutation Update($id: ID!, $name: String!${
              withSlug ? `, $slug: String` : ""
            }) {
                update${formValueSingular}(id: $id, name: $name${
      withSlug ? `, slug: $slug` : ""
    }) {
                    _id
                    name
                    ${withSlug ? "slug" : ""}
                }
            }
        `
  );

  const [addData] = useMutation(
    gql`
            mutation Add($name: String!) {
                add${formValueSingular}(name: $name) {
                    _id
                    name
                    ${withSlug ? "slug" : ""}
                }
            }
        `
  );

  const [deleteData] = useMutation(
    gql`
            mutation Delete($id: ID!) {
                delete${formValueSingular}(id: $id) {
                    _id
                    name
                }
            }
        `
  );

  const [newData, setNewData] = useState("");

  if (loading) {
    return <Placeholder />;
  }

  if (error) {
    return <>{error.message}</>;
  }

  return (
    <AdminLayout>
      <h2 className="ui header">
        Administrate {formValuePlural.replace(/([A-Z])/g, " $1").toLowerCase()}
      </h2>
      <Input
        fluid
        value={newData}
        placeholder={`Type some ${formValuePlural} here...`}
        onChange={(_, { value }) => setNewData(value)}
        action={{
          content: "add",
          color: "green",
          onClick: () =>
            addData({ variables: { name: newData } }).then((_) => refetch()),
        }}
      />

      {data &&
        (Array.isArray(data[formValuePlural]) ? data[formValuePlural] : []).map(
          (d: FormValue) => (
            <Segment key={d._id}>
              {withSlug ? (
                <>
                  Slug(s) used to link with dthinking academy, comma separated
                  list:
                  <Input
                    fluid
                    defaultValue={
                      updatedData && updatedData._id === d._id
                        ? updatedData.slug
                        : d.slug
                    }
                    onChange={(_, { value }) =>
                      updateData({
                        variables: { id: d._id, slug: value, name: d.name },
                      })
                    }
                  />
                </>
              ) : (
                ""
              )}
              <Input
                fluid
                defaultValue={
                  updatedData && updatedData._id === d._id
                    ? updatedData.name
                    : d.name
                }
                action={{
                  content: "delete",
                  color: "red",
                  onClick: () =>
                    deleteData({ variables: { id: d._id } }).then((_) =>
                      refetch()
                    ),
                }}
                onChange={(_, { value }) =>
                  updateData(
                    withSlug
                      ? {
                          variables: { id: d._id, name: value, slug: d.slug },
                        }
                      : {
                          variables: { id: d._id, name: value },
                        }
                  )
                }
              />
            </Segment>
          )
        )}
    </AdminLayout>
  );
}

export function DefaultFormValueSelect({
  formValueName,
  placeholder,
  multiple,
  value,
  onChange,
}: {
  formValueName: string;
  placeholder?: string;
  multiple?: boolean;
  value: { _id: string; name?: string }[];
  onChange: (newValue: { _id: string; name?: string }[]) => void;
}) {
  const { data: formValueList, error } = useQuery(
    gql`
        query FormValue {
            ${formValueName} {
                _id
                name
            }
        }
        `
  );

  if (error) {
    return (
      <>
        We failed to load required data to display this form, please retry in
        few moments.
      </>
    );
  }

  const formValueOptions: { key: string; text: string; value: string }[] = (
    (formValueList && formValueList[formValueName]) ||
    []
  ).map((formValue: any) => {
    return {
      key: formValue._id,
      text: formValue.name,
      value: formValue._id,
    };
  });

  const handleFormValueChanged = (formValueId: string | string[]) => {
    if (Array.isArray(formValueId)) {
      onChange(
        formValueId.map((id) => ({
          name: formValueOptions.find((formValue) => formValue.key === id)
            ?.text,
          _id: id,
        }))
      );
    } else {
      onChange([
        {
          name: formValueOptions.find(
            (formValue) => formValue.key === formValueId
          )?.text,
          _id: formValueId,
        },
      ]);
    }
  };

  return (
    <Dropdown
      placeholder={placeholder}
      fluid
      multiple={multiple}
      search
      selection
      options={formValueOptions}
      value={
        multiple
          ? value.map((val) => val._id)
          : value.length > 0 && value[0]
          ? value[0]._id
          : undefined
      }
      onChange={(__, newState) =>
        handleFormValueChanged(newState.value as string | string[])
      }
    />
  );
}
