import React, { useState, useEffect } from "react";
import { ContributorHeader } from "./Header";
import {
  Modal,
  Button,
  Input,
  TextArea,
  Form,
  CardGroup,
} from "semantic-ui-react";
import { isMobile } from "./services/utils";
import { DayPickerRangeController } from "react-dates";
import moment from "moment";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import FullEventFragment, { IEvent } from "./services/FullEventFragment";
import { eventAndFeedbackGraphqlClient } from ".";
import SelectContributor from "./SelectContributor";
import SelectValidatedContributorTrainings from "./SelectValidatedContributorTrainings";
import { DefaultFormValueSelect } from "./admin/AdminFormValue";
import EventCard, { humanReadableLocationType } from "./EventCard";

export function ContributorEventsPage() {
  return (
    <div className="ui grid" style={{ margin: 0 }}>
      <div
        className="ui column grey"
        style={{
          minHeight: "100vh",
          paddingTop: isMobile() ? undefined : "7em",
          paddingLeft: isMobile() ? undefined : "2em",
        }}
      >
        <ContributorHeader />
        <ContributorEvents />
      </div>
    </div>
  );
}

export function ContributorEvents() {
  const [createdEvents, setCreatedEvents] = useState<IEvent[]>([]);
  return (
    <div style={{ color: "black" }}>
      <PlanOrUpdateEvent
        onEventCreated={(newEvent) => {
          setCreatedEvents([...createdEvents, newEvent]);
        }}
      />
      <br />
      <br />

      <MyEvents
        type="all"
        appendEvents={createdEvents}
        onRefetch={() => setCreatedEvents([])}
      />
    </div>
  );
}

export function MyEvents({
  type,
  appendEvents,
  onRefetch,
}: {
  type: "all" | "planned" | "passed" | "cancelled";
  appendEvents: IEvent[];
  onRefetch: () => void;
}) {
  const {
    data: { myEvents: eventList } = { myEvents: [] },
    error,
    refetch,
  } = useQuery<{
    myEvents: IEvent[];
  }>(
    gql`
      ${FullEventFragment}
      query MyEvents {
        myEvents {
          ...FullEvent
        }
      }
    `,
    { client: eventAndFeedbackGraphqlClient }
  );

  if (error) {
    return <>We failed to load your events, please retry in few minutes</>;
  }

  return (
    <CardGroup>
      {[...eventList, ...appendEvents].map((event) => (
        <EventCard
          event={event}
          key={event._id}
          editButton={
            <PlanOrUpdateEvent
              existingEvent={event}
              onEventUpdated={(event) => {
                refetch();
                onRefetch();
              }}
            />
          }
        />
      ))}
    </CardGroup>
  );
}

export function PlanOrUpdateEvent({
  onEventCreated,
  onEventUpdated,
  existingEvent,
}: {
  onEventCreated?: (event: IEvent) => void;
  onEventUpdated?: (event: IEvent) => void;
  existingEvent?: IEvent;
}) {
  const [isModalOpen, setOpenModal] = useState(false);
  const [focusedDate, setFocusedDate] = useState<"startDate" | "endDate">(
    "startDate"
  );

  const { register, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      ...existingEvent,
      startDate: existingEvent?.startDate || moment().format("YYYY-MM-DD"),
      endDate: existingEvent?.endDate || moment().format("YYYY-MM-DD"),
    },
  });
  const type = watch("type");
  const audience = watch("audience");
  const onlineLocationDescription = watch(
    "onlineLocationDescription"
  ) as string;
  const startTime = watch("startTime");
  const trainingTypes = watch("trainingTypes") as {
    _id: string;
    name: string;
    category: "PRACTITIONER" | "CATALYST" | "ARTIST";
  }[];
  const competencies = watch("competencies") as string[];
  const startDate = watch("startDate") as string;
  const endDate = watch("endDate") as string;
  const leadTrainer = watch("leadTrainer") as string;
  const language = watch("language") as { _id: string; name: string };
  const locationType = watch("location.type") as string;
  const coLeadTrainers = watch("coLeadTrainers") as string[];

  useEffect(() => {
    register({ name: "type" });
    register({ name: "language" });
    register({ name: "leadTrainer" });
    register({ name: "location.type" });
    register({ name: "location.onlineLocationDescription" });
    register({ name: "audience" });
    register({ name: "startDate" });
    register({ name: "trainingTypes" });
    register({ name: "endDate" });
    register({ name: "competencies" });
    register({ name: "coLeadTrainers" });
  }, [register]);

  const [createEvent] = useMutation<{ createEvent: IEvent }>(
    gql`
      ${FullEventFragment}
      mutation CreateEvent($event: EventInput) {
        createEvent(event: $event) {
          ...FullEvent
        }
      }
    `,
    { client: eventAndFeedbackGraphqlClient }
  );

  const [patchEvent] = useMutation<{ patchEvent: IEvent }>(
    gql`
      ${FullEventFragment}
      mutation CreateEvent($id: ID!, $event: EventInput) {
        patchEvent(id: $id, event: $event) {
          ...FullEvent
        }
      }
    `,
    { client: eventAndFeedbackGraphqlClient }
  );

  const { data: { isAdmin } = { isAdmin: false } } = useQuery<{
    isAdmin: boolean;
  }>(
    gql`
      query GetProfile {
        isAdmin
      }
    `
  );

  const availableTrainingLocations = isAdmin
    ? [
        "SELF_PACE",
        "IN_PERSON",
        "INSTRUCTOR_LED_ONLINE",
        "FACILITATED_ONLINE",
        "BLENDED",
      ]
    : ["IN_PERSON", "INSTRUCTOR_LED_ONLINE"];

  return (
    <Modal
      size="fullscreen"
      closeIcon
      open={isModalOpen}
      onClose={() => setOpenModal(!isModalOpen)}
      trigger={
        <Button onClick={() => setOpenModal(!isModalOpen)}>
          {existingEvent ? "Update" : "Create a new event"}
        </Button>
      }
    >
      <Modal.Header>
        {existingEvent ? "Update this event" : "Create a new event"}
      </Modal.Header>
      <Modal.Content>
        <Form
          onSubmit={handleSubmit((event) => {
            if (existingEvent) {
              patchEvent({ variables: { id: existingEvent._id, event } }).then(
                (data) => {
                  if (data.data?.patchEvent && onEventUpdated) {
                    onEventUpdated(data.data?.patchEvent);
                  }
                  setOpenModal(false);
                }
              );
            } else {
              createEvent({ variables: { event } }).then((data) => {
                if (data.data?.createEvent && onEventCreated) {
                  onEventCreated(data.data?.createEvent);
                }
                setOpenModal(false);
              });
            }
          })}
        >
          <h3 style={{ marginBottom: 0 }}>Select a type</h3>
          <p style={{ fontSize: "1.2em", color: "#777777", marginTop: 0 }}>
            Training workshop, Consulting or Employment
          </p>
          <Button.Group>
            <Button
              active={type === "TRAINING"}
              onClick={(e) => {
                e.preventDefault();
                setValue("type", "TRAINING");
              }}
            >
              Training
            </Button>
            <Button.Or />
            <Button
              active={type === "CONSULTANT"}
              onClick={(e) => {
                e.preventDefault();
                setValue("type", "CONSULTANT");
              }}
            >
              Consulting
            </Button>
            <Button.Or />
            <Button
              active={type === "EMPLOYMENT"}
              onClick={(e) => {
                e.preventDefault();
                setValue("type", "EMPLOYMENT");
              }}
            >
              Employment
            </Button>
          </Button.Group>
          <h3 style={{ marginBottom: 0 }}>Lead trainer</h3>
          <SelectContributor
            selectMeByDefault
            values={[{ _id: leadTrainer }]}
            onChange={(leadTrainers) => {
              if (leadTrainers.length > 0 && leadTrainers[0]._id) {
                setValue("leadTrainer", leadTrainers[0]._id);
              }
            }}
          />
          <h3 style={{ marginBottom: 0 }}>Co-lead trainers</h3>
          <p style={{ fontSize: "1.2em", color: "#777777", marginTop: 0 }}>
            Search a co lead trainer by typing his/her exact lastname
          </p>
          <SelectContributor
            multiple
            notIncludeMe
            maxNumberOfItems={4}
            values={(coLeadTrainers || []).map((coLeadId) => ({
              _id: coLeadId,
            }))}
            onChange={(newCoLeadTrainers) => {
              setValue(
                "coLeadTrainers",
                newCoLeadTrainers.map((coLead) => coLead._id)
              );
            }}
          />
          {type === "TRAINING" && (
            <>
              <h3 style={{ marginBottom: 0 }}>DThinking training</h3>
              <SelectValidatedContributorTrainings
                multiple
                contributorId={leadTrainer}
                values={trainingTypes || []}
                onChange={(trainings) => setValue("trainingTypes", trainings)}
              />
            </>
          )}
          <h3 style={{ marginBottom: 0 }}>Audience</h3>
          <p style={{ fontSize: "1.2em", color: "#777777", marginTop: 0 }}>
            Public, Private, Student
          </p>
          <Button.Group>
            <Button
              active={audience === "PUBLIC"}
              onClick={(e) => {
                e.preventDefault();
                setValue("audience", "PUBLIC");
              }}
            >
              Public
            </Button>
            <Button.Or />
            <Button
              active={audience === "PRIVATE"}
              onClick={(e) => {
                e.preventDefault();
                setValue("audience", "PRIVATE");
              }}
            >
              Private
            </Button>
            <Button.Or />
            <Button
              active={audience === "STUDENT"}
              onClick={(e) => {
                e.preventDefault();
                setValue("audience", "STUDENT");
              }}
            >
              Student
            </Button>
          </Button.Group>

          <h3>Language</h3>
          <DefaultFormValueSelect
            formValueName="languages"
            value={[language]}
            onChange={(newLanguage) =>
              newLanguage.length > 0
                ? setValue("language", newLanguage[0])
                : undefined
            }
          />

          <h3 style={{ marginBottom: 0 }}>Competencies</h3>
          <p style={{ fontSize: "1.2em", color: "#777777", marginTop: 0 }}>
            Select competencies as a trainer you will be using during the event
          </p>
          <DefaultFormValueSelect
            formValueName="competencies"
            multiple
            value={(competencies || []).map((competencyId) => ({
              _id: competencyId,
            }))}
            onChange={(competencies) =>
              setValue(
                "competencies",
                competencies.map((competency) => competency._id)
              )
            }
          />
          <h3>Location</h3>

          <Button.Group size={isMobile() ? "mini" : undefined}>
            {availableTrainingLocations
              .map<React.ReactNode>((locationKind) => (
                <Button
                  active={locationType === locationKind}
                  key={locationKind}
                  onClick={(e) => {
                    e.preventDefault();
                    setValue("location.type", locationKind);
                  }}
                >
                  {humanReadableLocationType(locationKind)}
                </Button>
              ))
              .reduce((prev, curr, index) => [
                prev,
                <Button.Or key={`${index}-or`} />,
                curr,
              ])}
          </Button.Group>
          <br />
          <br />

          {(locationType === "IN_PERSON" || locationType === "BLENDED") && (
            <>
              <p style={{ fontSize: "1.2em", color: "#777777", marginTop: 0 }}>
                Only the city and country are required
              </p>
              <Form.Field>
                <Input
                  placeholder="Name (company or building name )"
                  name="location.locationName"
                  input={{ ref: register }}
                />
              </Form.Field>
              <Form.Field>
                <Input
                  placeholder="Street number"
                  name="location.streetNumber"
                  input={{ ref: register }}
                />
              </Form.Field>
              <Form.Field>
                <Input
                  placeholder="Street name"
                  name="location.streetName"
                  input={{ ref: register }}
                />
              </Form.Field>
              <Form.Field>
                <Input
                  placeholder="Additional address information"
                  name="location.addtional"
                  input={{ ref: register }}
                />
              </Form.Field>
              <Form.Field>
                <Input
                  placeholder="Zip code"
                  name="location.zipCode"
                  input={{ ref: register }}
                />
              </Form.Field>
              <Form.Field>
                <Input
                  placeholder="City"
                  name="location.city"
                  input={{ ref: register({ required: true }) }}
                />
              </Form.Field>
              <Form.Field>
                <Input
                  placeholder="Country"
                  name="location.country"
                  input={{ ref: register({ required: true }) }}
                />
              </Form.Field>
            </>
          )}

          {locationType !== "IN_PERSON" && (
            <>
              <Form.Field>
                <Input
                  placeholder="Online location url"
                  name="location.onlineLocationUrl"
                  input={{ ref: register }}
                />
              </Form.Field>
              <Form.Field>
                <TextArea
                  placeholder="Online location description"
                  name="location.onlineLocationDescription"
                  onChange={(__, newState) =>
                    setValue(
                      "location.onlineLocationDescription",
                      newState.value
                    )
                  }
                >
                  {onlineLocationDescription}
                </TextArea>
              </Form.Field>
            </>
          )}
          <h3>Number of hours</h3>
          <Form.Field>
            <Input
              placeholder="Number of hours"
              name="numberOfHours"
              input={{ ref: register }}
            />
          </Form.Field>

          <h3>Number of days</h3>
          <Form.Field>
            <Input
              placeholder="Number of days"
              name="numberOfDays"
              input={{ ref: register }}
            />
          </Form.Field>

          <h3>Number of weeks</h3>
          <Form.Field>
            <Input
              placeholder="Number of weeks"
              name="numberOfWeeks"
              input={{ ref: register }}
            />
          </Form.Field>

          <h3>Number of hours per week</h3>
          <Form.Field>
            <Input
              placeholder="Number of hours per weeks"
              name="numberOfHoursPerWeek"
              input={{ ref: register }}
            />
          </Form.Field>
          <h3 style={{ marginBottom: 0 }}>
            Select a period ({startDate} - {endDate})
          </h3>
          <p style={{ fontSize: "1.2em", color: "#777777", marginTop: 0 }}>
            It can be a single or multiple days event
          </p>
          <DayPickerRangeController
            numberOfMonths={2}
            orientation={isMobile() ? "vertical" : "horizontal"}
            startDate={moment(startDate)}
            endDate={moment(endDate)}
            focusedInput={focusedDate}
            minimumNights={0}
            onDatesChange={({ startDate, endDate }) => {
              setValue(
                "startDate",
                startDate?.format("YYYY-MM-DD") || moment().format("YYYY-MM-DD")
              );
              setValue(
                "endDate",
                endDate?.format("YYYY-MM-DD") ||
                  startDate?.format("YYYY-MM-DD") ||
                  moment().format("YYYY-MM-DD")
              );
            }}
            onFocusChange={(newFocusedDate) =>
              setFocusedDate(newFocusedDate || "startDate")
            }
          />
          {locationType !== "FACILITATED_ONLINE" && (
            <>
              <h3 style={{ marginBottom: 0 }}>Time schedule</h3>
              <p style={{ fontSize: "1.2em", color: "#777777", marginTop: 0 }}>
                Start and end time of the event
              </p>
              <Form.Field>
                <Input
                  placeholder="Start time"
                  type="time"
                  name="startTime"
                  input={{ ref: register }}
                />
                <Input
                  placeholder="End time"
                  type="time"
                  min={startTime}
                  name="endTime"
                  input={{ ref: register }}
                />
              </Form.Field>
            </>
          )}

          <h3>Organizer</h3>
          <Form.Field>
            <Input name="organizer" input={{ ref: register }} />
          </Form.Field>
          <h3 style={{ marginBottom: 0 }}>Website</h3>
          <p style={{ fontSize: "1.2em", color: "#777777", marginTop: 0 }}>
            Website describing the event
          </p>
          <Form.Field>
            <Input name="website" input={{ ref: register }} />
          </Form.Field>
          <h3 style={{ marginBottom: 0 }}>Registration email</h3>
          <p style={{ fontSize: "1.2em", color: "#777777", marginTop: 0 }}>
            Email to which candidates have to send registration request
          </p>
          <Form.Field>
            <Input
              name="registrationEmail"
              input={{ ref: register }}
              type="email"
            />
          </Form.Field>

          <Button size="big" type="submit">
            {existingEvent ? "Update" : "Create"}
          </Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
}
