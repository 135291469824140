import React, { useEffect, ChangeEvent, useState } from "react";
import { ContributorHeader } from "./Header";
import { Container, Divider, Form, Input, TextArea, Grid, Button, Dropdown, Select, Label, Icon, Segment, Popup, Message, Placeholder, Tab, CardGroup } from "semantic-ui-react";
import { isSmallScreen } from "./App";
import EditableAvatar from "./EditableAvatar";
import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { useForm } from 'react-hook-form';
import FullContributorFragment from "./services/FullContributorFragment";
import UpdateContributorMutation from "./services/UpdateContributorMutation";
import FormValue from "./services/FormValue";
import SponsorshipForm from "./SponsorhipForm";
import { useLocation, useHistory } from "react-router";
import ContributorCompetency, { ICompetency } from "./ContributorCompetency";
import { isMobile } from "./services/utils";
import { LocalizedTextareaField } from "./admin/AdminFeedbackQuestions";

const range = (start: number, stop: number, step: number = 1) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));

export function ContributorProfilePage() {
    return <div className='ui grid' style={{ margin: 0 }}>
    <div className='ui column grey' style={{ minHeight: '100vh', paddingTop: isMobile() ? undefined : '7em', paddingLeft: isMobile() ? undefined : '2em' }}>
        <ContributorHeader />
        <ContributorProfile />
        </div>
    </div>
}

export default function ContributorProfile({contributorId}: {contributorId?: string}) {
    const { loading, error, data: { profile } = { profile: undefined } } = useQuery<{
        profile: {
            _id: string,
            firstName: string,
            profilePictureUrl: string,
            halfBodyPictureUrl: string,
            competencies: ICompetency[]
        }
    }>(
        gql`
            ${FullContributorFragment}
            query GetProfile {
                profile: ${contributorId ? `contributor(id: "${contributorId}")` : 'me'} {
                    ...FullContributor
                }
            }
        `, {

    }
    );

    (profile?.competencies || []).forEach((competency: any) => {
        delete competency.__typename
    });
    

    const [updateContributor] = useMutation(UpdateContributorMutation);

    const history = useHistory();
    const location = useLocation();
    const urlSearchParams = new URLSearchParams(location.search);
    const currentPane = urlSearchParams.get('pane');

    return (
                <Container text style={{ color: 'black' }}>
                    <Grid >
                        {loading && <Placeholder>
                            <Placeholder.Header image>
                                <Placeholder.Line />
                                <Placeholder.Line />
                            </Placeholder.Header>
                        </Placeholder>}
                        {error && <>{error.message}</>}
                        {!loading && !error && profile !== undefined && <Grid.Row >
                            {!isSmallScreen && <Grid.Column width={8}>
                                <Grid>
                                    <Grid.Row>
                                    <Grid.Column width={8}>
                                        <EditableAvatar size='big' profileUrl={profile.profilePictureUrl} />
                                    </Grid.Column>
                                    <Grid.Column width={8}>
                                        <EditableAvatar size='big' profileUrl={profile.halfBodyPictureUrl} halfBody={true} />
                                    </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>}
                            <Grid.Column width={!isSmallScreen ? 8 : 16}>
                                <Divider hidden />
                                <Divider hidden />

                                {isSmallScreen && <>
                                    <EditableAvatar size='big' profileUrl={profile.profilePictureUrl} />
                                    <EditableAvatar size='big' profileUrl={profile.halfBodyPictureUrl} halfBody={true} />
                                </>}
                                <h2 className='ui header'>
                                    Welcome {profile.firstName},
                                <div className='ui sub header'>
                                        Complete your contributor profile
                                </div>
                                </h2>
                            </Grid.Column>
                        </Grid.Row>}
                    </Grid>

                    <Divider hidden />

                    {!loading && !error && profile !== undefined && <>
                        <Tab
                            activeIndex={parseInt(currentPane || '0', 10)}
                            menu={{ secondary: true, pointing: true }}
                            onTabChange={(_, { activeIndex }) => {
                                history.push(location.pathname + '?pane=' + activeIndex)
                            }
                            }
                            panes={[
                                {
                                    menuItem: 'Profile',
                                    render: () => (
                                        <Tab.Pane as={'div'}>
                                            <Divider hidden />
                                            <Divider hidden />
                                            <ContributorForm profile={profile} />
                                        </Tab.Pane>
                                    )
                                },
                                {
                                    menuItem: 'Competencies',
                                    render: () => (
                                        <Tab.Pane as={'div'}>
                                            <Divider hidden />
                                            <Divider hidden />
                                            <CardGroup>
                                                <ContributorCompetency isNew={true} saveCompetency={(competency) => updateContributor({
                                                    variables: {
                                                        id: profile._id, newProfile: {
                                                            competencies: [...(profile.competencies || []), competency]
                                                        }
                                                    }
                                                })} />

                                                {(profile.competencies || []).map(competency => {
                                                    return <ContributorCompetency
                                                         isNew={false}
                                                         key={`profile-${competency._id}`}
                                                         competency={competency} 
                                                         onDelete={(competencyId) => {
                                                            const newCompetencies = (profile.competencies || []).flatMap(oldCompetency => {
                                                                if (oldCompetency._id === competencyId) {
                                                                    return [];
                                                                }
                                                                //@ts-ignore
                                                                delete oldCompetency.__typename
                                                                return [oldCompetency];
                                                            })
                                                            updateContributor({
                                                                variables: {
                                                                    id: profile._id, newProfile: {
                                                                        competencies: newCompetencies
                                                                    }
                                                                }
                                                            })     
                                                         }}
                                                         saveCompetency={(competency) => {
                                                        const newCompetencies = (profile.competencies || []).map(oldCompetency => {
                                                            if (oldCompetency._id === competency._id) {
                                                                //@ts-ignore
                                                                delete competency.__typename
                                                                return competency;
                                                            }
                                                            //@ts-ignore
                                                            delete oldCompetency.__typename
                                                            return oldCompetency;
                                                        })
                                                        updateContributor({
                                                            variables: {
                                                                id: profile._id, newProfile: {
                                                                    competencies: newCompetencies
                                                                }
                                                            }
                                                        })
                                                    }} />
                                                })}
                                            </CardGroup>
                                        </Tab.Pane>
                                    )
                                },
                                {
                                    menuItem: 'Sponsorship',
                                    render: () => (
                                        <Tab.Pane as={'div'}>
                                            <Divider hidden />
                                            <Divider hidden />
                                            <SponsorshipForm profile={profile} />
                                        </Tab.Pane>
                                    )
                                }
                            ]}>

                        </Tab>

                    </>}
                </Container>
    )
}

const formValueToOption = (formValue: FormValue) => ({
    key: formValue._id,
    text: formValue.name,
    value: formValue._id
})

function ContributorForm({ profile }: { profile: any }) {
    const [displaySuccessBanner, setDisplaySuccessBanner] = useState(false);
    const { loading, error, data } = useQuery<{
        languages: FormValue[],
        dthinkingPractitionerCategories: FormValue[],
        dthinkingCatalystCategories: FormValue[],
        dthinkingArtistCategories: FormValue[],
        currencies: FormValue[]
    }>(
        gql`
            query GetFormValues {
                languages {
                    _id
                    name
                }
                
                dthinkingPractitionerCategories {
                    _id
                    name
                }
                
                dthinkingCatalystCategories {
                    _id
                    name
                }
                
                dthinkingArtistCategories {
                    _id
                    name
                }
                    
                currencies {
                    _id
                    name
                }
            }
        `, {

    }
    );
    const [updateContributor] = useMutation(UpdateContributorMutation);
    (profile.languages || []).forEach((lang: any) => {
        delete lang.__typename;
    });
    [...(profile.trainerInformation?.dthinkingArtist || []),
    ...(profile.trainerInformation?.dthinkingCatalyst || []),
    ...(profile.trainerInformation?.dthinkingPractitioner || [])].forEach(level => {
        delete level.__typename;
        delete level.category.__typename;
    })
    delete profile.consultantInformation?.dailyRate?.__typename;
    delete profile.consultantInformation?.dailyRate?.currency?.__typename;

    const { register, handleSubmit, setValue, watch } = useForm({
        defaultValues: profile
    });

    const languagesOptions = [
        "default",
        ...(data?.languages || []).map((lang) => lang.name),
      ];

    const languages = watch('languages');
    const types = watch('types');
    const valueProposition = watch('consultantInformation.valueProposition');
    const freelancableConditions = watch('consultantInformation.freelancableConditions');
    const description = watch('trainerInformation.description');
    const startingYear = watch('trainerInformation.startingYear');
    const isEmployed = watch('employmentInformation.isEmployed');
    const geographicAvailability = watch('geographicAvailability');
    const showOnAcademyWebsite = watch('trainerInformation.showOnAcademyWebsite');
    const isDthinkingPractitioner = watch('trainerInformation.isDthinkingPractitioner');
    const isDthinkingCatalyst = watch('trainerInformation.isDthinkingCatalyst');
    const isDthinkingArtist = watch('trainerInformation.isDthinkingArtist');
    const showOnConsultingWebsite = watch('consultantInformation.showOnConsultingWebsite');
    const isPartOfFreelancerGroup = watch('consultantInformation.isPartOfFreelancerGroup');
    const isFreelancable = watch('consultantInformation.isFreelancable');
    const isFreelancer = watch('consultantInformation.isFreelancer');
    const contractType = watch('consultantInformation.contractType');
    const workingDaysPerWeek = watch('consultantInformation.workingDaysPerWeek');
    const freelancerAssignement = watch('consultantInformation.freelancerAssignement');
    const shortTerm = watch('consultantInformation.shortTerm');
    const longTerm = watch('consultantInformation.longTerm');

    useEffect(() => {
        register({ name: "employmentInformation.isEmployed" });
        register({ name: "geographicAvailability" });
        register({ name: "languages" });
        register({ name: "types" });
        register({ name: "consultantInformation.showOnConsultingWebsite" });
        register({ name: "consultantInformation.valueProposition" });
        register({ name: "consultantInformation.freelancableConditions" });
        register({ name: "consultantInformation.workingDaysPerWeek" });
        register({ name: "consultantInformation.isFreelancable" });
        register({ name: "consultantInformation.isFreelancer" });
        register({ name: "consultantInformation.contractType" });
        register({ name: "consultantInformation.isPartOfFreelancerGroup" });
        register({ name: "consultantInformation.freelancerAssignement" });
        register({ name: "trainerInformation.showOnAcademyWebsite" });
        register({ name: "trainerInformation.isDthinkingPractitioner" });
        register({ name: "trainerInformation.dthinkingPractitioner" });
        register({ name: "trainerInformation.isDthinkingCatalyst" });
        register({ name: "trainerInformation.dthinkingCatalyst" });
        register({ name: "trainerInformation.isDthinkingArtist" });
        register({ name: "trainerInformation.dthinkingArtist" });
        register({ name: "trainerInformation.description" });
        register({ name: "trainerInformation.startingYear" });
        register({ name: "consultantInformation.dailyRate.shortTerm" });
        register({ name: "consultantInformation.dailyRate.longTerm" });
        register({ name: "consultantInformation.dailyRate.currency" });
    }, [register])


    return <Form onSubmit={handleSubmit(newProfile => {
        if (profile) {
            updateContributor({ variables: { id: profile._id, newProfile } }).then(() => {
                setDisplaySuccessBanner(true)
                window.scrollTo({top: 0});
            })
        }
    })}>
        {displaySuccessBanner ? <Message color='green'>Your profile has successfully been updated !</Message> : <></>}
        <Form.Field>
            <label>Firstname</label>
            <Input name="firstName" input={{ ref: register }} disabled />
        </Form.Field>
        <Form.Field>
            <label>Lastname</label>
            <Input name="lastName" input={{ ref: register }} disabled />
        </Form.Field>
        <Form.Field>
            <label>Title</label>
            <Input name="title" input={{ ref: register }} />
        </Form.Field>
        <Divider horizontal>Social and professional profiles</Divider>
        <Form.Field>
            <label>Linkedin profile</label>
            <Input name="socialLinks.linkedIn" input={{ ref: register }} />
        </Form.Field>
        <Form.Field>
            <label>Facebook profile</label>
            <Input name="socialLinks.facebook" input={{ ref: register }} />
        </Form.Field>
        <Form.Field>
            <label>Instagram profile</label>
            <Input name="socialLinks.instagram" input={{ ref: register }} />
        </Form.Field>
        <Form.Field>
            <label>Youtube profile</label>
            <Input name="socialLinks.youtube" input={{ ref: register }} />
        </Form.Field>
        <Form.Field>
            <label>Tweeter profile</label>
            <Input name="socialLinks.tweeter" input={{ ref: register }} />
        </Form.Field>
        <Form.Field>
            <label>Professional website</label>
            <Input name="socialLinks.professionalWebsite" input={{ ref: register }} />
        </Form.Field>

        <Divider horizontal>Contact and employment</Divider>
        <Form.Field>
            <label>Street number</label>
            <Input name="contactInformation.address.streetNumber" input={{ ref: register }} />
        </Form.Field>
        <Form.Field>
            <label>Street name</label>
            <Input name="contactInformation.address.streetName" input={{ ref: register }} />
        </Form.Field>
        <Form.Field>
            <label>Addition</label>
            <Input name="contactInformation.address.addtional" input={{ ref: register }} />
        </Form.Field>
        <Form.Field>
            <label>Zip code</label>
            <Input name="contactInformation.address.zipCode" input={{ ref: register }} />
        </Form.Field>
        <Form.Field>
            <label>City</label>
            <Input name="contactInformation.address.city" input={{ ref: register }} />
        </Form.Field>
        <Form.Field>
            <label>Country</label>
            <Input name="contactInformation.address.country" input={{ ref: register }} />
        </Form.Field>
        <Form.Field>
            <label>Secondary country</label>
            <Input name="contactInformation.secondaryAddress.country" input={{ ref: register }} />
        </Form.Field>
        <Form.Field>
            <label>Secondary city</label>
            <Input name="contactInformation.secondaryAddress.city" input={{ ref: register }} />
        </Form.Field>
        <Form.Field>
            <label>Mobile phone number</label>
            <Input name="contactInformation.mobilePhone" input={{ ref: register }} />
        </Form.Field>
        <Form.Field>
            <label>Other phone number</label>
            <Input name="contactInformation.otherPhone" input={{ ref: register }} />
        </Form.Field>
        <Form.Field>
            <label>Email</label>
            <Input name="contactInformation.email" input={{ ref: register }} />
        </Form.Field>
        <Form.Field>
            <label>Other email</label>
            <Input name="contactInformation.otherEmail" input={{ ref: register }} />
        </Form.Field>
        <Form.Field>
            <label>Employee</label>
            <Button.Group>
                <Button active={isEmployed} onClick={(e) => { e.preventDefault(); setValue('employmentInformation.isEmployed', true)}}>Yes</Button>
                <Button.Or />
                <Button active={!isEmployed} onClick={(e) => { e.preventDefault(); setValue('employmentInformation.isEmployed', false)}}>No</Button>
            </Button.Group>
        </Form.Field>
        <Form.Field>
            <label>Company</label>
            <Input name="employmentInformation.company" input={{ ref: register }} />
        </Form.Field>
        <Form.Field>
            <label>Geographic availability</label>
            <Button.Group>
                <Button active={geographicAvailability === 'CITY'} onClick={(e) => { e.preventDefault(); setValue('geographicAvailability', 'CITY')}}>City</Button>
                <Button.Or text='>' />
                <Button active={geographicAvailability === 'REGION'} onClick={(e) => { e.preventDefault(); setValue('geographicAvailability', 'REGION')}}>Region</Button>
                <Button.Or text='>' />
                <Button active={geographicAvailability === 'CONTINENT'} onClick={(e) => { e.preventDefault(); setValue('geographicAvailability', 'CONTINENT')}}>Continent</Button>
                <Button.Or text='>' />
                <Button active={geographicAvailability === 'WORLD'} onClick={(e) => { e.preventDefault(); setValue('geographicAvailability', 'WORLD')}}>World</Button>
            </Button.Group>
        </Form.Field>
        <Form.Field>
            <label>Languages</label>
            {loading && <Placeholder />}
            {error && error.message}
            {!loading && !error && data && data.languages && <Dropdown
                fluid
                multiple
                selection
                value={(languages || []).map((lang: FormValue) => lang._id)}
                onChange={(_, { value }) => setValue('languages', (Array.isArray(value) ? value : [value]).map(val => ({ _id: val })))}
                options={data.languages.map(formValueToOption)} />}
        </Form.Field>
        <Divider horizontal>Invoicing information</Divider>
        <Form.Field>
            <label>Organization name</label>
            <Input name="invoicingInformation.organizationName" input={{ ref: register }} />
        </Form.Field>
        <Form.Field>
            <label>Email</label>
            <Input name="invoicingInformation.email" input={{ ref: register }} />
        </Form.Field>
        <Form.Field>
            <label>Street number</label>
            <Input name="invoicingInformation.address.streetNumber" input={{ ref: register }} />
        </Form.Field>
        <Form.Field>
            <label>Street name</label>
            <Input name="invoicingInformation.address.streetName" input={{ ref: register }} />
        </Form.Field>
        <Form.Field>
            <label>Addition</label>
            <Input name="invoicingInformation.address.addtional" input={{ ref: register }} />
        </Form.Field>
        <Form.Field>
            <label>Zip code</label>
            <Input name="invoicingInformation.address.zipCode" input={{ ref: register }} />
        </Form.Field>
        <Form.Field>
            <label>City</label>
            <Input name="invoicingInformation.address.city" input={{ ref: register }} />
        </Form.Field>
        <Form.Field>
            <label>Country</label>
            <Input name="invoicingInformation.address.country" input={{ ref: register }} />
        </Form.Field>
        <Form.Field>
            <label>VAT number</label>
            <Input name="invoicingInformation.vatNumber" input={{ ref: register }} />
        </Form.Field>

        <Divider horizontal>contributor</Divider>

        <Form.Field>
            <label>Would you like to become a trainer or a consultant (you can select both) ?</label>
            <Button.Group>
                <Button
                    active={(types || []).includes('TRAINER')}
                    onClick={() => {
                        if ((types || []).includes('TRAINER')) {
                            if ((types || []).includes('CONSULTANT')) {
                                setValue('types', ['CONSULTANT']);
                            } else {
                                setValue('types', []);
                            }
                        } else {
                            if ((types || []).includes('CONSULTANT')) {
                                setValue('types', ['CONSULTANT', 'TRAINER']);
                            } else {
                                setValue('types', ['TRAINER']);
                            }
                        }
                    }}>Trainer</Button>
                <Button
                    active={(types || []).includes('CONSULTANT')}
                    onClick={() => {
                        if ((types || []).includes('CONSULTANT')) {
                            if ((types || []).includes('TRAINER')) {
                                setValue('types', ['TRAINER']);
                            } else {
                                setValue('types', []);
                            }
                        } else {
                            if ((types || []).includes('TRAINER')) {
                                setValue('types', ['CONSULTANT', 'TRAINER']);
                            } else {
                                setValue('types', ['CONSULTANT']);
                            }
                        }
                    }}>Consultant</Button>
            </Button.Group>
        </Form.Field>

        {(types || []).includes('TRAINER') &&
            <div>
                <Divider horizontal>Trainer</Divider>
                <section>
                    <Form.Field>
                        <label>Do you want your profile to be published on the academy website ?</label>
                        <Button.Group>
                            <Button active={showOnAcademyWebsite} onClick={(e) => { e.preventDefault(); setValue('trainerInformation.showOnAcademyWebsite', true)}}>Yes</Button>
                            <Button.Or />
                            <Button active={!showOnAcademyWebsite} onClick={(e) => { e.preventDefault(); setValue('trainerInformation.showOnAcademyWebsite', false)}}>No</Button>
                        </Button.Group>
                    </Form.Field>

                    <Form.Field>
                        <label>Describe yourself</label>
                        <LocalizedTextareaField
                            languages={languagesOptions}
                            fieldId={"trainerInformation.description"}
                            label={"Describe yourself as a trainer. This information will be published on your trainer profile."}
                            value={description}
                            setValue={setValue}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Year you started as a dthinking trainer</label>
                        <Select options={
                            range(new Date().getFullYear() - 100, new Date().getFullYear()).map(year => ({
                                key: year,
                                text: year,
                                value: year
                            }))}
                            value={startingYear}
                            onChange={(_, { value }) => setValue('trainerInformation.startingYear', value)}
                        >
                        </Select>
                    </Form.Field>

                    <h4 className='ui header'>Select your dthinking level and which trainings you wish to deliver.
            <div className='ui sub header'>Then we will validate your choices.</div></h4>
                    <Form.Field>
                        <label>You are a dthinking practitioner</label>
                        <Segment>
                            <Button.Group>
                                <Button active={isDthinkingPractitioner} onClick={(e) => { e.preventDefault(); setValue('trainerInformation.isDthinkingPractitioner', true)}}>Yes</Button>
                                <Button.Or />
                                <Button active={!isDthinkingPractitioner} onClick={(e) => { e.preventDefault(); setValue('trainerInformation.isDthinkingPractitioner', false)}}>No</Button>
                            </Button.Group>

                            {(profile.trainerInformation?.dthinkingPractitioner || []).find((lvl: { category: FormValue, isCategoryValidated: boolean }) => lvl.isCategoryValidated) &&
                                <Popup content='Your practitioner profile have been validated' trigger={
                                    <Label color='green' size='large' corner>
                                        <Icon name='check' />
                                    </Label>} />}

                            <Divider hidden />
                            <Form.Group grouped>
                                {
                                    data && data.dthinkingPractitionerCategories.map(level => (
                                        <Message key={level._id}>
                                            <Form.Field
                                                label={level.name}
                                                checked={(profile.trainerInformation?.dthinkingPractitioner || []).find((lvl: { category: FormValue }) => lvl.category._id === level._id)}
                                                control='input'
                                                type='checkbox'
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                    if (e.target.checked) {
                                                        setValue('trainerInformation.dthinkingPractitioner', [...(profile.trainerInformation?.dthinkingPractitioner || []), { category: { _id: level._id, name: level.name } }])
                                                    } else {
                                                        setValue('trainerInformation.dthinkingPractitioner', [...(profile.trainerInformation?.dthinkingPractitioner || []).filter((lvl: { category: FormValue }) => lvl.category._id !== level._id)])
                                                    }
                                                }
                                                } />
                                            {(profile.trainerInformation?.dthinkingPractitioner || []).find((lvl: { category: FormValue, isCategoryValidated: boolean }) => lvl.category._id === level._id && lvl.isCategoryValidated) && <Label color='green' corner><Icon name='check' /></Label>}
                                        </Message>
                                    ))
                                }
                            </Form.Group>
                        </Segment>
                    </Form.Field>


                    <Form.Field>
                        <label>You are a dthinking catalyst</label>
                        <Segment>
                            <Button.Group>
                                <Button active={isDthinkingCatalyst} onClick={(e) => { e.preventDefault(); setValue('trainerInformation.isDthinkingCatalyst', true)}}>Yes</Button>
                                <Button.Or />
                                <Button active={!isDthinkingCatalyst} onClick={(e) => { e.preventDefault(); setValue('trainerInformation.isDthinkingCatalyst', false)}}>No</Button>
                            </Button.Group>

                            {(profile.trainerInformation?.dthinkingCatalyst || []).find((lvl: { category: FormValue, isCategoryValidated: boolean }) => lvl.isCategoryValidated) &&
                                <Popup content='Your catalyst profile have been validated' trigger={
                                    <Label color='green' size='large' corner>
                                        <Icon name='check' />
                                    </Label>} />}

                            <Divider hidden />
                            <Form.Group grouped>
                                {
                                    data && data.dthinkingCatalystCategories.map(level => (
                                        <Message key={level._id}>
                                            <Form.Field
                                                label={level.name}
                                                checked={(profile.trainerInformation?.dthinkingCatalyst || []).find((lvl: { category: FormValue }) => lvl.category._id === level._id)}
                                                control='input'
                                                type='checkbox'
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                    if (e.target.checked) {
                                                        setValue('trainerInformation.dthinkingCatalyst', [...(profile.trainerInformation?.dthinkingCatalyst || []), { category: { _id: level._id, name: level.name } }])
                                                    } else {
                                                        setValue('trainerInformation.dthinkingCatalyst', [...(profile.trainerInformation?.dthinkingCatalyst || []).filter((lvl: { category: FormValue }) => lvl.category._id !== level._id)])
                                                    }
                                                }
                                                } />
                                            {(profile.trainerInformation?.dthinkingCatalyst || []).find((lvl: { category: FormValue, isCategoryValidated: boolean }) => lvl.category._id === level._id && lvl.isCategoryValidated) && <Label color='green' corner><Icon name='check' /></Label>}
                                        </Message>
                                    ))
                                }
                            </Form.Group>
                        </Segment>
                    </Form.Field>

                    <Form.Field>
                        <label>You are a dthinking artist</label>
                        <Segment>
                            <Button.Group>
                                <Button active={isDthinkingArtist} onClick={(e) => { e.preventDefault(); setValue('trainerInformation.isDthinkingArtist', true)}}>Yes</Button>
                                <Button.Or />
                                <Button active={!isDthinkingArtist} onClick={(e) => { e.preventDefault(); setValue('trainerInformation.isDthinkingArtist', false)}}>No</Button>
                            </Button.Group>

                            {(profile.trainerInformation?.dthinkingArtist || []).find((lvl: { category: FormValue, isCategoryValidated: boolean }) => lvl.isCategoryValidated) &&
                                <Popup content='Your artist profile have been validated' trigger={
                                    <Label color='green' size='large' corner>
                                        <Icon name='check' />
                                    </Label>} />}

                            <Divider hidden />
                            <Form.Group grouped>
                                {
                                    data && data.dthinkingArtistCategories.map(level => (
                                        <Message key={level._id}>
                                            <Form.Field
                                                label={level.name}
                                                checked={(profile.trainerInformation?.dthinkingArtist || []).find((lvl: { category: FormValue }) => lvl.category._id === level._id)}
                                                control='input'
                                                type='checkbox'
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                    if (e.target.checked) {
                                                        setValue('trainerInformation.dthinkingArtist', [...(profile.trainerInformation?.dthinkingArtist || []), { category: { _id: level._id, name: level.name } }])
                                                    } else {
                                                        setValue('trainerInformation.dthinkingArtist', [...(profile.trainerInformation?.dthinkingArtist || []).filter((lvl: { category: FormValue }) => lvl.category._id !== level._id)])
                                                    }
                                                }
                                                } />
                                            {(profile.trainerInformation?.dthinkingArtist || []).find((lvl: { category: FormValue, isCategoryValidated: boolean }) => lvl.category._id === level._id && lvl.isCategoryValidated) && <Label color='green' corner><Icon name='check' /></Label>}
                                        </Message>
                                    ))
                                }
                            </Form.Group>
                        </Segment>
                    </Form.Field>

                </section>
            </div>
        }
        {(types || []).includes('CONSULTANT') && <div>
            <Divider horizontal>Consultant</Divider>
            <section>
                <Form.Field>
                    <label>Do you want your profile to be published on the consulting website ?</label>
                    <Button.Group>
                        <Button active={showOnConsultingWebsite} onClick={(e) => { e.preventDefault(); setValue('consultantInformation.showOnConsultingWebsite', true)}}>Yes</Button>
                        <Button.Or />
                        <Button active={!showOnConsultingWebsite} onClick={(e) => { e.preventDefault(); setValue('consultantInformation.showOnConsultingWebsite', false)}}>No</Button>
                    </Button.Group>
                </Form.Field>

                <Form.Field>
                    <label>Your short value value propsition available to customers</label>
                    <TextArea placeholder='Explains what makes you a great consultant...' value={valueProposition || ''} onChange={(_, { value }) => setValue('consultantInformation.valueProposition', value)} />
                </Form.Field>

                <Form.Field>
                    <label>As an employee, can you work freelance ?</label>
                    <Button.Group>
                        <Button active={isFreelancable} onClick={(e) => { e.preventDefault(); setValue('consultantInformation.isFreelancable', true)}}>Yes</Button>
                        <Button.Or />
                        <Button active={!isFreelancable} onClick={(e) => { e.preventDefault(); setValue('consultantInformation.isFreelancable', false)}}>No</Button>
                    </Button.Group>
                    <Divider hidden />
                    <TextArea placeholder='Why, which conditions' value={freelancableConditions || ''} onChange={(_, { value }) => setValue('consultantInformation.freelancableConditions', value)} />
                </Form.Field>

                <Form.Field>
                    <label>Freelancer</label>
                    <Button.Group>
                        <Button active={isFreelancer} onClick={(e) => { e.preventDefault(); setValue('consultantInformation.isFreelancer', true)}}>Yes</Button>
                        <Button.Or />
                        <Button active={!isFreelancer} onClick={(e) => { e.preventDefault(); setValue('consultantInformation.isFreelancer', false)}}>No</Button>
                    </Button.Group>
                    <Divider hidden />
                    <Input placeholder='Type or status' name='consultantInformation.freelancerStatus' input={{ ref: register }} />
                    <Divider hidden />

                    <label>Do you belong to a group of freelancer ?</label>
                    <Button.Group>
                        <Button active={isPartOfFreelancerGroup} onClick={(e) => { e.preventDefault(); setValue('consultantInformation.isPartOfFreelancerGroup', true)}}>Yes</Button>
                        <Button.Or />
                        <Button active={!isPartOfFreelancerGroup} onClick={(e) => { e.preventDefault(); setValue('consultantInformation.isPartOfFreelancerGroup', false)}}>No</Button>
                    </Button.Group>
                    <Divider hidden />
                    <Input placeholder='Freelancer group name' name='consultantInformation.freelancerGroupName' input={{ ref: register }} />
                    <Input placeholder='Freelancer group website' name='consultantInformation.freelancerGroupWebsite' input={{ ref: register }} />
                </Form.Field>

                <h4 className='ui header'>Describe the work assignment you are preferably looking for :</h4>

                <Form.Field>
                    <label>Contract type</label>
                    <Button.Group>
                        <Button active={contractType === 'PERMANENT'} onClick={(e) => { e.preventDefault(); setValue('consultantInformation.contractType', 'PERMANENT')}}>Permanent</Button>
                        <Button.Or />
                        <Button active={contractType === 'TEMPORARY'} onClick={(e) => { e.preventDefault(); setValue('consultantInformation.contractType', 'TEMPORARY')}}>Temporary</Button>
                        <Button.Or />
                        <Button active={contractType === 'FREELANCER'} onClick={(e) => { e.preventDefault(); setValue('consultantInformation.contractType', 'FREELANCER')}}>Freelancer</Button>
                    </Button.Group>
                </Form.Field>

                <Form.Field>
                    <label>Freelancer assignment</label>
                    <Button.Group>
                        <Button active={freelancerAssignement === 'SHORT_TERM'} onClick={(e) => { e.preventDefault(); setValue('consultantInformation.freelancerAssignement', 'SHORT_TERM')}}>Short term</Button>
                        <Button.Or />
                        <Button active={freelancerAssignement === 'LONG_TERM'} onClick={(e) => { e.preventDefault(); setValue('consultantInformation.freelancerAssignement', 'LONG_TERM')}}>Long term</Button>
                    </Button.Group>
                </Form.Field>

                <Form.Field>
                    <label>Working days per week</label>
                    <Button.Group>
                        <Button active={workingDaysPerWeek === 'ONE'} onClick={(e) => { e.preventDefault(); setValue('consultantInformation.workingDaysPerWeek', 'ONE')}}>1</Button>
                        <Button.Or />
                        <Button active={workingDaysPerWeek === 'TWO'} onClick={(e) => { e.preventDefault(); setValue('consultantInformation.workingDaysPerWeek', 'TWO')}}>2</Button>
                        <Button.Or />
                        <Button active={workingDaysPerWeek === 'THREE'} onClick={(e) => { e.preventDefault(); setValue('consultantInformation.workingDaysPerWeek', 'THREE')}}>3</Button>
                        <Button.Or />
                        <Button active={workingDaysPerWeek === 'FOUR'} onClick={(e) => { e.preventDefault(); setValue('consultantInformation.workingDaysPerWeek', 'FOUR')}}>4</Button>
                        <Button.Or />
                        <Button active={workingDaysPerWeek === 'FIVE'} onClick={(e) => { e.preventDefault(); setValue('consultantInformation.workingDaysPerWeek', 'FIVE')}}>5</Button>
                    </Button.Group>
                </Form.Field>

                <Form.Field>
                    <label>Daily rate</label>
                    <Select
                        placeholder='Currency'
                        value={profile.consultantInformation && profile.consultantInformation.dailyRate && profile.consultantInformation.dailyRate.currency && profile.consultantInformation.dailyRate.currency._id}
                        onChange={(_, { value }) => setValue('consultantInformation.dailyRate.currency', { _id: value, name: (data?.currencies || []).find(currency => currency._id === value)?.name })}
                        options={(data?.currencies || []).map(currency => ({ key: currency._id, text: currency.name, value: currency._id }))} />
                    <Input
                        placeholder='Short term'
                        type='number'
                        min={0}
                        value={shortTerm}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setValue('consultantInformation.dailyRate.shortTerm', parseFloat(e.target.value))
                        }} />
                    <Input placeholder='Long term' type='number' min={0}
                        value={longTerm}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setValue('consultantInformation.dailyRate.longTerm', parseFloat(e.target.value))
                        }} />
                </Form.Field>
            </section>
        </div>}
        <Divider hidden />
        <Button type='submit'>Save</Button>
    </Form>;
}