import React from "react";
import AdminFormValues from "./AdminFormValue";

const formValuePlural = "dthinkingPractitionerCategories";
const formValueSingular = "PractitionerCategory";

export default function AdminDthinkingPractitionerCategories() {
  return (
    <AdminFormValues
      withSlug
      formValuePlural={formValuePlural}
      formValueSingular={formValueSingular}
    />
  );
}
