import React, { useEffect } from "react";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { Dropdown } from "semantic-ui-react";

const minimalContributorGlobalCache: { [id: string]: IMinimalContributor } = {};

export default function SelectContributor({
  multiple,
  maxNumberOfItems,
  values,
  onChange,
  disabled,
  selectMeByDefault,
  disabledIfNonAdmin,
  notIncludeMe,
}: {
  multiple?: boolean;
  disabled?: boolean;
  notIncludeMe?: boolean;
  disabledIfNonAdmin?: boolean;
  selectMeByDefault?: boolean;
  maxNumberOfItems?: number;
  values: IMinimalContributor[];
  onChange: (newlySelectedContributors: IMinimalContributor[]) => void;
}) {
  const [
    searchContributor,
    { loading: isSearching, data } = { loading: false, data: { sponsors: [] } },
  ] = useLazyQuery(
    gql`
      query SearchSponsor($lastName: String) {
        sponsors(lastName: $lastName) {
          _id
          firstName
          lastName
          profilePictureUrl
        }
      }
    `
  );

  const {
    loading: isLoading,
    data: { profile, isAdmin } = { profile: undefined, isAdmin: false },
  } = useQuery<{
    isAdmin: boolean;
    profile: {
      _id: string;
      firstName: string;
      lastName: string;
      profilePictureUrl: string;
    };
  }>(
    gql`
            query GetProfile {
                profile: ${"me"} {
                    _id
                    firstName
                    lastName
                    profilePictureUrl
                }
                isAdmin
            }
        `
  );

  const rawOptions = [profile, ...(data?.sponsors || [])];
  rawOptions.forEach((contributor: IMinimalContributor) => {
    if (contributor) {
      minimalContributorGlobalCache[contributor._id] = contributor;
    }
  });

  const options = notIncludeMe
    ? Object.values(minimalContributorGlobalCache).filter(
        (contributor: IMinimalContributor) => contributor && contributor._id !== profile?._id
      )
    : Object.values(minimalContributorGlobalCache);
  const mappedOptions = options.map(mapContributorToItem);

  useEffect(() => {
    if (selectMeByDefault && !isLoading && profile) {
      onChange([profile]);
    }
    // eslint-disable-next-line
  }, [selectMeByDefault, isLoading, profile]);

  if (isLoading) {
    return <>Loading...</>;
  }

  const handleValueChange = (values: string[] | string) => {
    if (Array.isArray(values)) {
      if (values.length === maxNumberOfItems) {
        return;
      }
      onChange(
        values.map((value) =>
          options.find((option: IMinimalContributor) => option._id === value) as IMinimalContributor
        )
      );
    } else {
      onChange([
        options.find((option: IMinimalContributor) => option._id === values)  as IMinimalContributor,
      ]);
    }
  };

  return (
    <Dropdown
      fluid
      multiple={multiple}
      search
      selection
      disabled={disabled || (disabledIfNonAdmin && !isAdmin)}
      loading={isSearching || isLoading}
      options={mappedOptions}
      value={
        multiple
          ? values.map((contributor) => contributor._id)
          : values && values[0]
          ? values[0]._id
          : undefined
      }
      onSearchChange={(_, { searchQuery }) =>
        searchContributor({ variables: { lastName: searchQuery } })
      }
      onChange={(_, { value }) => handleValueChange(value as string[] | string)}
    />
  );
}

export interface IMinimalContributor {
  _id: string;
  firstName?: string;
  lastName?: string;
  profilePictureUrl?: string;
}

const mapContributorToItem = (contributor?: IMinimalContributor) => {
  if (!contributor)
    return { key: "loading", text: "loading", value: "loading" };
  return {
    key: contributor._id,
    text: contributor.firstName + " " + contributor.lastName,
    value: contributor._id,
    image: { avatar: true, src: contributor.profilePictureUrl },
  };
};
