import React from 'react';
import { Grid, Divider, Button } from 'semantic-ui-react';
import Header, { ROUTES } from './Header';
import ModalCompetencies from './ModalCompetencies';
import CookieBanner from './CookieBanner';
import { useHistory } from 'react-router';

export const isSmallScreen = window.innerWidth < 767;

export default function App() {
  const columStyle = () => ({ height: isSmallScreen ? `` : '98vh', paddingLeft: '2em', paddingRight: '2em' });

  const history = useHistory();

  return (
    <>
      <Header />

      <Grid stackable>
        <Grid.Row columns={2}>
          <Grid.Column className='yellow' style={columStyle()}>
          <Divider hidden />
          <Divider hidden />
          <Divider hidden />
          <Divider hidden />

            <h2 className='ui header black'>
              Find your contributor to your innovation, transformation or learning program<Divider hidden />
              <div className='ui sub header'>The dthinking platform connects you with top notch professionals</div>
            </h2>

            <Divider hidden />
            <Divider hidden />

            <Button color='red' size="big" onClick={() => history.push(ROUTES.login.getPath('contributor'))}>Find your contributor</Button>
            <Divider hidden />

            </Grid.Column>
          <Grid.Column className='green' style={columStyle()}>
          {!isSmallScreen && <>
          <Divider hidden />
          <Divider hidden />
          <Divider hidden />
          </>}
          <Divider hidden />
            <h2 className='ui header black'>Start offering your <span style={{whiteSpace: 'nowrap'}}>competencies<sup>
              <ModalCompetencies />
            </sup></span>
            <Divider hidden />

              <div className='ui sub header'>Request an invite to join the dthinking platform and continue building your credibility</div>
            </h2>

            <Divider hidden />
            <Divider hidden />

            <Button color='black' size="big" onClick={() => history.push(ROUTES.login.getPath('customer'))}>Request an invite</Button>
            <Divider hidden />
            
            </Grid.Column>
        </Grid.Row>
      </Grid>

      <CookieBanner />
    </>
  );
}

