import React, { useState } from "react";
import { Modal, Icon, Button } from "semantic-ui-react";

export default function ModalCompetencies() {
    const [isModalOpenned, setIsModalOpened] = useState(false);

    const toggle = () => {
        setIsModalOpened(!isModalOpenned);
    }

    return (
        <Modal
          trigger={<Icon style={{cursor: 'pointer', fontSize: '1.3em'}} name='info circle' onClick={toggle} />}
          open={isModalOpenned}
          onClose={toggle}
          basic
          size='small'
        >
          <Modal.Content>
            <h3 className='ui header' style={{color: 'white'}}>
              <u>Designer</u>: <div className='ui sub header' style={{color: 'white'}}>product & service design, design thinking, industrial design, business model design, design management, design leadership</div>
            </h3>
            <h3 className='ui header' style={{color: 'white'}}>
              <u>Innovator</u>: <div className='ui sub header' style={{color: 'white'}}>lean startup, scrum, agile, sprint, governance, open, participative, creativity, creative problem solving, collective intelligence, team coaching, workshop facilitation</div>
            </h3>
            <h3 className='ui header' style={{color: 'white'}}>
              <u>Change manager</u>: <div className='ui sub header' style={{color: 'white'}}>change management, transformation, team coaching</div>
            </h3>
            <h3 className='ui header' style={{color: 'white'}}>
              <u>Social science</u>: <div className='ui sub header' style={{color: 'white'}}>qualitative market research, people research, anthropology, sociology, ethnology, semiology, psychology</div>
            </h3>
            <h3 className='ui header' style={{color: 'white'}}>
              <u>Technology</u>: <div className='ui sub header' style={{color: 'white'}}>artificial intelligence, blockchain, chatbot, robotics, augmented reality, IT</div>
            </h3>
            <h3 className='ui header' style={{color: 'white'}}>
              <u>Scribing</u>: <div className='ui sub header' style={{color: 'white'}}>graphic facilitation, graphic recording</div>
            </h3>
            <h3 className='ui header' style={{color: 'white'}}>
              <u>Theater</u>: <div className='ui sub header' style={{color: 'white'}}>public speaking, storytelling, theater forum</div>
            </h3>
            <h3 className='ui header' style={{color: 'white'}}>
              <u>Voice</u>: <div className='ui sub header' style={{color: 'white'}}>vocal individual coaching, vocal team coaching</div>
            </h3>
            <h3 className='ui header' style={{color: 'white'}}>
              <u>Video</u>: <div className='ui sub header' style={{color: 'white'}}>institutional film, stop motion, animation movie</div>
            </h3>
          </Modal.Content>
          <Modal.Actions>
            <Button color='green' onClick={toggle} inverted>
              <Icon name='checkmark' /> Close
            </Button>
          </Modal.Actions>
        </Modal>
      )
}