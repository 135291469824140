import React from "react";
import AdminFormValues from "./AdminFormValue";

const formValuePlural = "dthinkingCatalystCategories";
const formValueSingular = "CatalystCategory";

export default function AdminDthinkingCatalystCategories() {
  return (
    <AdminFormValues
      withSlug
      formValuePlural={formValuePlural}
      formValueSingular={formValueSingular}
    />
  );
}
