import { QueryLazyOptions, useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

export function useFetchContributors(): [
  (options?: QueryLazyOptions<{ ids: string[] }> | undefined) => void,
  {
    [key: string]: { firstName: string; lastName: string };
  }
] {
  const [fetchLinkedContributors, { data: contributors }] = useLazyQuery<{
    contributorsWithId: {
      _id: string;
      firstName: string;
      lastName: string;
    }[];
  }>(gql`
    query Contributors($ids: [ID]) {
      contributorsWithId(ids: $ids) {
        _id
        firstName
        lastName
      }
    }
  `);

  let normalizedContributors: {
    [key: string]: { firstName: string; lastName: string };
  } = {};
  if (contributors && contributors.contributorsWithId) {
    normalizedContributors = contributors.contributorsWithId.reduce(
      (previous, current) => ({
        ...previous,
        [current._id]: {
          firstName: current.firstName,
          lastName: current.lastName,
        },
      }),
      {}
    );
  }

  return [fetchLinkedContributors, normalizedContributors];
}
