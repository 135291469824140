import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { Dropdown } from "semantic-ui-react";
import React from "react";

export function SelectLanguage() {
    const {
        loading: loadingLocales,
        data: locales,
      } = useQuery<{ languages: { name: string }[] }>(gql`
        query Locales {
          languages {
            name
          }
        }
      `);
    
      const languages = [
        ...(locales?.languages || []).map((lang) => lang.name),
      ];
      
      const options = [{key: "default", text: "Select your language", value: "default"}, ...languages.map(lang => ({key: lang, text: lang, value: lang}))];

      if (loadingLocales) {
          return <>Loading ...</>;
      }

      return <Dropdown options={options} value={localStorage.getItem("selectedLang") || "default"} onChange={(_, {value}) => {
          localStorage.setItem("selectedLang", value as string);
          window.location.reload();
      }} />
}