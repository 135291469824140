import gql from "graphql-tag";

const FullEventFragment = gql`
  fragment FullEvent on Event {
    _id
    leadTrainer
    coLeadTrainers
    type
    audience
    competencies
    trainingTypes {
      _id
      name
      category
    }
    language {
      _id
      name
    }
    startDate
    endDate
    startTime
    endTime
    timeZone
    location {
      onlineLocationUrl
      onlineLocationDescription
      locationName
      streetNumber
      streetName
      addtional
      zipCode
      city
      country
      type
    }
    website
    registrationEmail
    isValidatedByAdmin
    feedbackLinkId
  }
`;

export default FullEventFragment;

export interface IEvent {
  _id: string;
  leadTrainer: string;
  coLeadTrainers: string[];
  type: string;
  audience: string;
  competencies: string[];
  trainingTypes: {
    _id: string;
    name: string;
    category: string;
  }[];
  language: {
    _id: string;
    name: string;
  };
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  timeZone: string;
  location: {
    onlineLocationUrl: string;
    onlineLocationDescription: string;
    locationName: string;
    streetNumber: string;
    streetName: string;
    addtional: string;
    zipCode: string;
    city: string;
    country: string;
    type: string;
  };
  website: string;
  registrationEmail: string;
  isValidatedByAdmin: boolean;
  feedbackLinkId: string;
}
