import React from "react";
import AdminFormValues from "./AdminFormValue";

const formValuePlural = "dthinkingArtistCategories";
const formValueSingular = "ArtistCategory";

export default function AdminDthinkingArtistCategories() {
  return (
    <AdminFormValues
      withSlug
      formValuePlural={formValuePlural}
      formValueSingular={formValueSingular}
    />
  );
}
