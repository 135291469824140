import React, { useEffect } from "react";
import { IEvent } from "./services/FullEventFragment";
import { FeedbackQuestions } from "./admin/AdminFeedbackQuestions";
import {
  Form,
  Rating,
  TextArea,
  Input,
  Checkbox,
  Button,
} from "semantic-ui-react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { eventAndFeedbackGraphqlClient } from ".";

type ContributorsFNLN = {
  [contributorId: string]: { firstName: string; lastName: string };
};

const replaceVariableInString = (
  content: string,
  variableName: string,
  value: string
) => {
  if (!value) return content;
  return content.replace("{" + variableName + "}", value);
};

export interface IFeedback {
  leadTrainerRecommendation: IRecommendation;
  eventRecommendation: IRecommendation;
  hostRecommendation: IRecommendation;
  showLastName: boolean;
  firstName: string;
  lastName: string;
  email: string;
  city: string;
  country: string;
  organization: string;
  recommendationDate: string;
  _id: string;
  subscribeToDthinkingAcademyNewsletter: boolean;
  coLeadTrainersRecommendation: {
    coLeadTrainerId: string;
    recommendation: IRecommendation;
  }[];
}

export default function FeedbackForm({
  event,
  feedbackQuestions,
  contributors,
  onFeedbackSubmitted,
}: {
  event: IEvent;
  feedbackQuestions: FeedbackQuestions;
  contributors: ContributorsFNLN;
  onFeedbackSubmitted: (feedback: IFeedback) => void;
}) {
  const { register, handleSubmit, setValue, watch, errors } =
    useForm<IFeedback>({
      defaultValues: {
        leadTrainerRecommendation: {},
        coLeadTrainersRecommendation: [],
        eventRecommendation: {},
        hostRecommendation: {},
      },
    });

  const leadTrainerRecommendation = watch("leadTrainerRecommendation");
  const coLeadTrainersRecommendation = watch("coLeadTrainersRecommendation");
  const eventRecommendation = watch("eventRecommendation");
  const hostRecommendation = watch("hostRecommendation");
  const showLastName = watch("showLastName");
  const subscribeToDthinkingAcademyNewsletter = watch(
    "subscribeToDthinkingAcademyNewsletter"
  );

  useEffect(() => {
    register(
      { name: "leadTrainerRecommendation" },
      {
        required: true,
        validate: (value) => {
          return !!(
            value.grade &&
            value.positiveComment &&
            value.improveComment
          );
        },
      }
    );
    register({ name: "coLeadTrainersRecommendation" });
    register({ name: "showLastName" });
    register({ name: "subscribeToDthinkingAcademyNewsletter" });
    register(
      { name: "eventRecommendation" },
      {
        required: true,
        validate: (value) =>
          !!(value.grade && value.positiveComment && value.improveComment),
      }
    );
    register(
      { name: "hostRecommendation" },
      {
        required: true,
        validate: (value) =>
          !!(value.grade && value.positiveComment && value.improveComment),
      }
    );
  }, [register]);

  const [postFeedback] = useMutation<{ postFeedback: IFeedback }>(
    gql`
      mutation PostFeedback(
        $eventFeedbackLinkId: ID!
        $feedback: FeedbackInput
      ) {
        postFeedback(
          eventFeedbackLinkId: $eventFeedbackLinkId
          feedback: $feedback
        ) {
          _id
          eventId
          leadTrainerRecommendation {
            grade
            positiveComment
            improveComment
          }
          coLeadTrainersRecommendation {
            coLeadTrainerId
            recommendation {
              grade
              positiveComment
              improveComment
            }
          }
          hostRecommendation {
            grade
            positiveComment
            improveComment
          }
          eventRecommendation {
            grade
            positiveComment
            improveComment
          }
          firstName
          lastName
          city
          country
          email
          organization
          recommendationDate
          isFeedbackVisible
          leadTrainerHideImproveRecommendation
          coLeadTrainerHideImproveRecommendation
          hostHideImproveRecommendation
          showLastName
          subscribeToDthinkingAcademyNewsletter
        }
      }
    `,
    { client: eventAndFeedbackGraphqlClient }
  );

  const replaceLeadTrainerInfo = (content: string) =>
    replaceVariableInString(
      replaceVariableInString(
        content,
        "leadTrainer.firstName",
        contributors[event.leadTrainer]?.firstName
      ),
      "leadTrainer.lastName",
      contributors[event.leadTrainer]?.lastName
    );
  const replaceCoLeadTrainerInfo = (content: string, coLeadTrainerId: string) =>
    replaceVariableInString(
      replaceVariableInString(
        content,
        "coLeadTrainer.firstName",
        contributors[coLeadTrainerId]?.firstName
      ),
      "coLeadTrainer.lastName",
      contributors[coLeadTrainerId]?.lastName
    );

  return (
    <Form
      onSubmit={handleSubmit((feedback) => {
        postFeedback({
          variables: {
            eventFeedbackLinkId: event.feedbackLinkId,
            feedback: feedback,
          },
        }).then((submitedFeedback) => {
          if (submitedFeedback.data?.postFeedback) {
            onFeedbackSubmitted(submitedFeedback.data?.postFeedback);
          } else {
            alert(feedbackQuestions.errorSubmittingFeedback);
          }
        });
      })}
    >
      <h2>{replaceLeadTrainerInfo(feedbackQuestions.feedbackTitle)}</h2>
      <h3>{replaceLeadTrainerInfo(feedbackQuestions.feedbackIntroduction)}</h3>

      <h3>
        {replaceLeadTrainerInfo(feedbackQuestions.feedbackTrainerSectionTitle)}
      </h3>

      <RecommendationQuestion
        feedbackQuestions={feedbackQuestions}
        inError={errors["leadTrainerRecommendation"]}
        label={replaceLeadTrainerInfo(feedbackQuestions.leadTrainerQuestion)}
        positiveCommentLabel={replaceLeadTrainerInfo(
          feedbackQuestions.positiveCommentDescription
        )}
        improveCommentLabel={replaceLeadTrainerInfo(
          feedbackQuestions.improveCommentDescription
        )}
        recommendation={leadTrainerRecommendation}
        onRecommendationChange={(newRecommendation) =>
          setValue("leadTrainerRecommendation", newRecommendation)
        }
      />

      {(event.coLeadTrainers || []).map((coLeadTrainer) => (
        <RecommendationQuestion
          feedbackQuestions={feedbackQuestions}
          key={coLeadTrainer}
          label={replaceCoLeadTrainerInfo(
            replaceLeadTrainerInfo(feedbackQuestions.coLeadTrainerQuestion),
            coLeadTrainer
          )}
          positiveCommentLabel={replaceCoLeadTrainerInfo(
            replaceLeadTrainerInfo(
              feedbackQuestions.positiveCommentDescription
            ),
            coLeadTrainer
          )}
          improveCommentLabel={replaceCoLeadTrainerInfo(
            replaceLeadTrainerInfo(feedbackQuestions.improveCommentDescription),
            coLeadTrainer
          )}
          recommendation={
            coLeadTrainersRecommendation.find(
              (coLeadTrainerRecommendation) =>
                coLeadTrainerRecommendation.coLeadTrainerId === coLeadTrainer
            )?.recommendation || ({} as IRecommendation)
          }
          onRecommendationChange={(newRecommendation) => {
            if (
              coLeadTrainersRecommendation.find(
                (coLeadTrainerRecommendation) =>
                  coLeadTrainerRecommendation.coLeadTrainerId === coLeadTrainer
              )
            ) {
              coLeadTrainersRecommendation.forEach((coLeadTrainerReco) => {
                if (coLeadTrainerReco.coLeadTrainerId === coLeadTrainer) {
                  coLeadTrainerReco.recommendation = newRecommendation;
                }
              });
            } else {
              coLeadTrainersRecommendation.push({
                coLeadTrainerId: coLeadTrainer,
                recommendation: newRecommendation,
              });
            }
            setValue(
              "coLeadTrainersRecommendation",
              coLeadTrainersRecommendation
            );
          }}
        />
      ))}

      <h3>
        {replaceLeadTrainerInfo(feedbackQuestions.feedbackWorkshopSectionTitle)}
      </h3>

      <RecommendationQuestion
        feedbackQuestions={feedbackQuestions}
        inError={errors["eventRecommendation"]}
        label={replaceLeadTrainerInfo(feedbackQuestions.eventQuestion)}
        positiveCommentLabel={replaceLeadTrainerInfo(
          feedbackQuestions.positiveCommentDescription
        )}
        improveCommentLabel={replaceLeadTrainerInfo(
          feedbackQuestions.improveCommentDescription
        )}
        recommendation={eventRecommendation}
        onRecommendationChange={(newRecommendation) =>
          setValue("eventRecommendation", newRecommendation)
        }
      />

      <h3>
        {replaceLeadTrainerInfo(feedbackQuestions.feedbackOrgSectionTitle)}
      </h3>

      <RecommendationQuestion
        feedbackQuestions={feedbackQuestions}
        inError={errors["hostRecommendation"]}
        label={replaceLeadTrainerInfo(feedbackQuestions.hostQuestion)}
        positiveCommentLabel={replaceLeadTrainerInfo(
          feedbackQuestions.positiveCommentDescription
        )}
        improveCommentLabel={replaceLeadTrainerInfo(
          feedbackQuestions.improveCommentDescription
        )}
        recommendation={hostRecommendation}
        onRecommendationChange={(newRecommendation) =>
          setValue("hostRecommendation", newRecommendation)
        }
      />

      <h3>
        {replaceLeadTrainerInfo(feedbackQuestions.personalDataDescription)}
      </h3>

      <h3 style={errors["firstName"] && { color: "red" }}>
        {feedbackQuestions.firstName}
        {errors["firstName"] && (
          <>{feedbackQuestions.errorRequiredFieldSubfix}</>
        )}
      </h3>
      <Input
        fluid
        name="firstName"
        input={{ ref: register({ required: true }) }}
      />

      <h3 style={errors["lastName"] && { color: "red" }}>
        {feedbackQuestions.lastName}
        {errors["lastName"] && (
          <>{feedbackQuestions.errorRequiredFieldSubfix}</>
        )}
      </h3>
      <Input
        fluid
        name="lastName"
        input={{ ref: register({ required: true }) }}
      />

      <h3 style={errors["city"] && { color: "red" }}>
        {feedbackQuestions.city}
        {errors["city"] && <>{feedbackQuestions.errorRequiredFieldSubfix}</>}
      </h3>
      <Input fluid name="city" input={{ ref: register({ required: true }) }} />

      <h3 style={errors["country"] && { color: "red" }}>
        {feedbackQuestions.country}
        {errors["country"] && <>{feedbackQuestions.errorRequiredFieldSubfix}</>}
      </h3>
      <Input
        fluid
        name="country"
        input={{ ref: register({ required: true }) }}
      />

      <h3 style={errors["email"] && { color: "red" }}>
        {feedbackQuestions.email}
        {errors["email"] && <>{feedbackQuestions.errorRequiredFieldSubfix}</>}
      </h3>
      <Input
        fluid
        name="email"
        type="email"
        input={{ ref: register({ required: true }) }}
      />

      <h3>{feedbackQuestions.organization}</h3>
      <Input
        fluid
        name="organization"
        input={{ ref: register({ required: false }) }}
      />

      <h3>
        <Checkbox
          checked={showLastName}
          onChange={(_, { checked }) => {
            setValue("showLastName", !!checked);
          }}
          label={
            <label>
              {replaceLeadTrainerInfo(
                feedbackQuestions.showLastNameDescription
              )}
            </label>
          }
        />
      </h3>
      <h3>
        <Checkbox
          checked={subscribeToDthinkingAcademyNewsletter}
          onChange={(_, { checked }) => {
            setValue("subscribeToDthinkingAcademyNewsletter", !!checked);
          }}
          label={
            <label>
              {replaceLeadTrainerInfo(feedbackQuestions.newsletterDescription)}
            </label>
          }
        />
      </h3>

      {errors && Object.keys(errors).length > 0 && (
        <h3 style={{ color: "red" }}>{feedbackQuestions.errorMissingField}</h3>
      )}
      <Button type="submit">{feedbackQuestions.submitYourFeedback}</Button>
    </Form>
  );
}

export interface IRecommendation {
  grade: number;
  positiveComment: string;
  improveComment: string;
}

export function RecommendationQuestion({
  inError,
  recommendation,
  onRecommendationChange,
  label,
  positiveCommentLabel,
  improveCommentLabel,
  feedbackQuestions,
}: {
  label: string;
  positiveCommentLabel: string;
  improveCommentLabel: string;
  inError?: any;
  feedbackQuestions: FeedbackQuestions;
  recommendation: IRecommendation;
  onRecommendationChange: (newRecommendation: IRecommendation) => void;
}) {
  return (
    <>
      <h3 style={inError && { color: "red" }}>
        {label}
        {inError && <>{feedbackQuestions.errorRequiredFieldSubfix}</>}
      </h3>
      <Rating
        rating={recommendation.grade}
        maxRating={5}
        size="massive"
        onRate={(__, newRating) =>
          onRecommendationChange({
            ...recommendation,
            grade: newRating.rating as number,
          })
        }
      />
      <h4 style={inError && { color: "red" }}>{positiveCommentLabel}</h4>
      <TextArea
        value={recommendation.positiveComment}
        onChange={(__, newState) =>
          onRecommendationChange({
            ...recommendation,
            positiveComment: newState.value as string,
          })
        }
      ></TextArea>
      <h4 style={inError && { color: "red" }}>{improveCommentLabel}</h4>
      <TextArea
        value={recommendation.improveComment}
        onChange={(__, newState) =>
          onRecommendationChange({
            ...recommendation,
            improveComment: newState.value as string,
          })
        }
      ></TextArea>
    </>
  );
}
