import { Icon, Image } from 'semantic-ui-react';
import React, { useState, HTMLProps, useEffect } from 'react';

interface IProps extends Omit<HTMLProps<HTMLDivElement>, 'size'> {
    size: 'avatar' | 'big';
    src: string;
}

export default function Avatar({size, src, ...props}: IProps) {
    const [isFallbackDisplayed, setDisplayFallback] = useState(false);

    const setImgToFallback = () => setDisplayFallback(true);

    useEffect(() => {
        if (!src) {
            setDisplayFallback(true);
        } else {
            setDisplayFallback(false);
        }
    }, [src]);

    if (isFallbackDisplayed) {
        switch (size) {
            case 'avatar':
            default:
                return <div {...props}><Icon size='big' name='user circle' /></div>;
            case 'big':
                return <div {...props}><Icon size='massive' name='user circle' /></div>;
        }
    }
    switch (size) {
        case 'avatar':
        default:
            return <div {...props}><Image avatar src={src} onError={setImgToFallback} /></div>
        case 'big':
            return <div {...props}><img style={{maxHeight: "128px", maxWidth: "100%"}} alt='linkedin profile avatar' src={src} onError={setImgToFallback} /></div>
    }

}