import React from "react";
import AdminFormValues from "./AdminFormValue";


const formValuePlural = 'languages'
const formValueSingular = 'Language'

export default function AdminLanguages() {
    return <AdminFormValues formValuePlural={formValuePlural} formValueSingular={formValueSingular} />
}

