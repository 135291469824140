import React from "react";
import AdminLayout from "./AdminLayout";
import { useParams } from "react-router-dom";
import ContributorProfile from "../ContributorProfile";

export default function AdminProfile() {
    const {contributorId} = useParams();

    return <AdminLayout>
        <ContributorProfile contributorId={contributorId === 'undefined' ? undefined : contributorId} />
    </AdminLayout>
}