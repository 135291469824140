import AdminLayout from "./AdminLayout";
import React, { ChangeEvent } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import FullContributorFragment from "../services/FullContributorFragment";
import { Placeholder, Table, Form, Icon, Button } from "semantic-ui-react";
import UpdateContributorMutation from "../services/UpdateContributorMutation";
import { ROUTES } from "../Header";

export default function AdminValidateContributorDthinkingCategories() {
    const { loading, error, data } = useQuery(
        gql`
            ${FullContributorFragment}
            query GetAllContributors {
                contributors {
                    ...FullContributor
                }
            }
        `
    )

    const [updateContributor] = useMutation(UpdateContributorMutation);

    const [validateShowOnAcademyWebsite] = useMutation(gql`
        ${FullContributorFragment}
        mutation ValidateShowOnAcademyWebsite($id: ID!, $validated: Boolean) {
            validateShowOnAcademyWebsite(id: $id, validated: $validated) {
                ...FullContributor
            }
        }
    `)

    const [validateShowOnConsultingWebsite] = useMutation(gql`
        ${FullContributorFragment}
        mutation ValidateShowOnConsultingWebsite($id: ID!, $validated: Boolean) {
            validateShowOnConsultingWebsite(id: $id, validated: $validated) {
                ...FullContributor
            }
        }
    `)

    const validateCategory = (contributor: any, category: string) => (categoryWithValidationStatus: any, index: number) => (<>
        <Form.Field
            key={categoryWithValidationStatus.category._id}
            label={'Validate ' + categoryWithValidationStatus.category.name}
            checked={categoryWithValidationStatus.isCategoryValidated}
            control='input'
            type='checkbox'
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (e.target.checked) {
                    categoryWithValidationStatus.isCategoryValidated = true;
                } else {
                    categoryWithValidationStatus.isCategoryValidated = false;
                }
                contributor.trainerInformation[category][index] = categoryWithValidationStatus;

                // if (contributor.trainerInformation && contributor.trainerInformation.showOnAcademyWebsiteValidated != null) {
                //     delete contributor.trainerInformation.showOnAcademyWebsiteValidated;
                // }

                updateContributor({variables: {id: contributor._id, newProfile: {
                    trainerInformation: {
                        ...contributor.trainerInformation,
                        showOnAcademyWebsiteValidated: undefined
                    }
                }}});
            }
        }/>
    </>);

    return <AdminLayout>
        <h2 className='ui header'>Validate contributors dthinking categories</h2>

        {loading && <Placeholder></Placeholder>}
        {error && error.message}

        <Table celled sortable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Full Name</Table.HeaderCell>
                    <Table.HeaderCell>Dthinking Practitioner</Table.HeaderCell>
                    <Table.HeaderCell>Dthinking Catalyst</Table.HeaderCell>
                    <Table.HeaderCell>Dthinking Artist</Table.HeaderCell>
                    <Table.HeaderCell>Show on academy website</Table.HeaderCell>
                    <Table.HeaderCell>Show on consulting website</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {data && data.contributors.map((contributor: any) => {
                    [...(contributor.trainerInformation?.dthinkingArtist || []),
                    ...(contributor.trainerInformation?.dthinkingCatalyst || []),
                    ...(contributor.trainerInformation?.dthinkingPractitioner || [])].forEach(level => {
                        delete level.__typename;
                        delete level.category.__typename;
                    })
                    if (contributor.trainerInformation) {
                        delete contributor.trainerInformation.__typename;
                    }
                    return <Table.Row>
                        <Table.Cell>
                            <a href={ROUTES.adminContributorProfile.getPath(contributor._id)} target="about:blank">
                                {contributor.firstName} {contributor.lastName} <Icon name="external" />
                            </a>
                        </Table.Cell>
                        <Table.Cell>
                            {(contributor.trainerInformation ?.dthinkingPractitioner || []).map(validateCategory(contributor, 'dthinkingPractitioner'))}
                        </Table.Cell>
                        <Table.Cell>
                            {(contributor.trainerInformation ?.dthinkingCatalyst || []).map(validateCategory(contributor, 'dthinkingCatalyst'))}
                        </Table.Cell>
                        <Table.Cell>
                            {(contributor.trainerInformation ?.dthinkingArtist || []).map(validateCategory(contributor, 'dthinkingArtist'))}
                        </Table.Cell>
                        <Table.Cell>
                            {!contributor.trainerInformation ?.showOnAcademyWebsite && <>
                                contributor has not checked showOnAcademyWebsite
                            </>}
                            {contributor.trainerInformation ?.showOnAcademyWebsite && contributor.trainerInformation ?.showOnAcademyWebsiteValidated && <>
                                Current status : validated<br/>
                                <Button color='red' onClick={() => validateShowOnAcademyWebsite({variables: {id: contributor._id, validated: false}})}>Invalidate</Button>
                            </>}
                            {contributor.trainerInformation ?.showOnAcademyWebsite && !contributor.trainerInformation ?.showOnAcademyWebsiteValidated && <>
                                Current status : not validated<br/>
                                <Button color='green' onClick={() => validateShowOnAcademyWebsite({variables: {id: contributor._id, validated: true}})}>Validate</Button>
                            </>}
                        </Table.Cell>
                        <Table.Cell>
                            {!contributor.consultantInformation ?.showOnAcademyWebsite && <>
                                contributor has not checked showOnConsultingWebsite
                            </>}
                            {contributor.consultantInformation ?.showOnConsultingWebsite && contributor.consultantInformation ?.showOnConsultingWebsiteValidated && <>
                                Current status : validated<br/>
                                <Button color='red' onClick={() => validateShowOnConsultingWebsite({variables: {id: contributor._id, validated: false}})}>Invalidate</Button>
                            </>}
                            {contributor.consultantInformation ?.showOnConsultingWebsite && !contributor.consultantInformation ?.showOnConsultingWebsiteValidated && <>
                                Current status : not validated<br/>
                                <Button color='green' onClick={() => validateShowOnConsultingWebsite({variables: {id: contributor._id, validated: true}})}>Validate</Button>
                            </>}
                        </Table.Cell>
                    </Table.Row>
                })}

            </Table.Body>
        </Table>

    </AdminLayout>
}