import { Menu, Button, Icon } from "semantic-ui-react";
import React, { useState } from "react";
import { useHistory } from "react-router";
import App from "./App";
import Login from "./Login";
import { ContributorProfilePage } from "./ContributorProfile";
import AdminLanguages from "./admin/AdminLanguages";
import AdminCurrencies from "./admin/AdminCurrencies";
import AdminDthinkingPractitionerCategories from "./admin/AdminDthinkingPractitionerCategories";
import AdminDthinkingCatalystCategories from "./admin/AdminDthinkingCatalystCategories";
import AdminDthinkingArtistCategories from "./admin/AdminDthinkingArtistCategories";
import { ReactComponentLike } from "prop-types";
import AdminValidateContributorDthinkingCategories from "./admin/AdminValidateContributorDthinkingCategories";
import AdminCompetencies from "./admin/AdminCompetencies";
import AdminProfile from "./admin/AdminProfile";
import { ContributorEventsPage } from "./ContributorEvents";
import { Location, LocationState } from "history";
import { isDesktop, isMobile } from "./services/utils";
import AdminValidateEvents from "./admin/AdminValidateEvents";
import { GiveFeedbackPage } from "./GiveFeedback";
import AdminFeedbackQuestions from "./admin/AdminFeedbackQuestions";
import { EventFeedbackPage } from "./EventFeedback";

interface IHeaderProps {}

type Route = {
  component: ReactComponentLike;
  path: string;
  isExact: boolean;
  redirectToWhenLogggedOut?: string;
  getPath: (...args: any) => string;
};

function isCurrent(route: Route, location: Location<LocationState>) {
  return !!location.pathname.match(
    route.path.replace(/:[^/]+/, "[^/]+").replace("/", "/")
  );
}

export const ROUTES: {
  [routeKey: string]: Route;
} = {
  home: {
    component: App,
    path: "/",
    isExact: true,
    getPath: () => "/",
  },
  login: {
    component: Login,
    path: "/login/:type",
    isExact: false,
    getPath: (type: "customer" | "contributor") => `/login/${type}`,
  },
  contributorProfile: {
    component: ContributorProfilePage,
    path: "/contributor/profile",
    isExact: false,
    redirectToWhenLogggedOut: "/login/contributor",
    getPath: () => "/contributor/profile",
  },
  contributorEvents: {
    component: ContributorEventsPage,
    path: "/contributor/events",
    isExact: true,
    redirectToWhenLogggedOut: "/login/contributor",
    getPath: () => "/contributor/events",
  },
  giveFeedback: {
    component: GiveFeedbackPage,
    path: "/feedbacks/:eventFeedbackLinkId",
    isExact: false,
    getPath: (eventFeedbackLinkId: string) => `/feedbacks/${eventFeedbackLinkId}`,
  },
  eventFeedback: {
    component: EventFeedbackPage,
    path: "/contributor/events/:eventId/feedbacks",
    isExact: false,
    getPath: (eventId: string) => `/contributor/events/${eventId}/feedbacks`,
  },
  adminContributorProfile: {
    component: AdminProfile,
    path: "/admin/contributors/:contributorId",
    isExact: false,
    redirectToWhenLogggedOut: "/login/contributor",
    getPath: (contributorId: string) => `/admin/contributors/${contributorId}`,
  },
  adminLanguages: {
    component: AdminLanguages,
    path: "/admin/languages",
    isExact: false,
    redirectToWhenLogggedOut: "/login/contributor",
    getPath: () => "/admin/languages",
  },
  adminCurrencies: {
    component: AdminCurrencies,
    path: "/admin/currencies",
    isExact: false,
    redirectToWhenLogggedOut: "/login/contributor",
    getPath: () => "/admin/currencies",
  },
  adminCompetencies: {
    component: AdminCompetencies,
    path: "/admin/competencies",
    isExact: false,
    redirectToWhenLogggedOut: "/login/contributor",
    getPath: () => "/admin/competencies",
  },
  adminDthinkingPractitionerCategories: {
    component: AdminDthinkingPractitionerCategories,
    path: "/admin/categories/practioner",
    isExact: false,
    redirectToWhenLogggedOut: "/login/contributor",
    getPath: () => "/admin/categories/practioner",
  },
  adminDthinkingCatalystCategories: {
    component: AdminDthinkingCatalystCategories,
    path: "/admin/categories/catalyst",
    isExact: false,
    redirectToWhenLogggedOut: "/login/contributor",
    getPath: () => "/admin/categories/catalyst",
  },
  adminDthinkingArtistCategories: {
    component: AdminDthinkingArtistCategories,
    path: "/admin/categories/artist",
    isExact: false,
    redirectToWhenLogggedOut: "/login/contributor",
    getPath: () => "/admin/categories/artist",
  },
  adminValidateContributorsDthinkingCategories: {
    component: AdminValidateContributorDthinkingCategories,
    path: "/admin/categories/validate",
    isExact: false,
    redirectToWhenLogggedOut: "/login/contributor",
    getPath: () => "/admin/categories/validate",
  },
  adminValidateEvents: {
    component: AdminValidateEvents,
    path: "/admin/events",
    isExact: false,
    redirectToWhenLogggedOut: "/login/contributor",
    getPath: () => "/admin/events",
  },
  adminFeedbackQuestions: {
    component: AdminFeedbackQuestions,
    path: "/admin/feedback-questions",
    isExact: false,
    getPath: () => "/admin/feedback-questions",
  }
};

export default function Header({
  children,
}: React.PropsWithChildren<IHeaderProps>) {
  const history = useHistory();
  return (
    <Menu stackable size="massive" fixed={isDesktop() ? "top" : undefined}>
      <Menu.Item
        header
        className="borderless"
        link
        onClick={() => history.push(ROUTES.home.getPath())}
      >
        <img src="/logo.png" alt="logo" />{" "}
        <span style={{ color: "#b85f57" }}>
          <b>d</b>thinking.<i>network</i>
        </span>
      </Menu.Item>
      {children}
    </Menu>
  );
}

export function ContributorHeader({
  children,
}: React.PropsWithChildren<IHeaderProps>) {
  const history = useHistory();
  const [isOpen, setOpeness] = useState(false);

  return (
    <header style={isMobile() ? {marginBottom: "2em"} : {}}>
      <div style={{ display: "flex" }}>
        <Menu
          size={"massive"}
          fixed={isDesktop() ? "top" : undefined}
          style={
            isMobile() ? {
              flexGrow: 1,
              margin: 0,
              borderRight: 0,
              borderRadius: "unset",
              borderBottom: 0,
              boxShadow: '0px 0px 2px 0 rgba(34, 36, 38, 0.15)'
            }: {}
          }
        >
          <Menu.Item
            header
            className="borderless"
            link
            onClick={() => history.push(ROUTES.home.getPath())}
          >
            <img src="/logo.png" alt="logo" />{" "}
            <span style={{ color: "#b85f57" }}>
              <b>d</b>thinking.<i>network</i>
            </span>
          </Menu.Item>
          {isDesktop() && (
            <InternalContributorMenu>{children}</InternalContributorMenu>
          )}
        </Menu>
        {isMobile() && (
          <Menu style={{ margin: 0, borderLeft: 0, borderRadius: "unset", borderBottom: 0, boxShadow: '0px 0px 2px 0 rgba(34, 36, 38, 0.15)' }}>
            <Menu.Menu position="right" className="borderless">
              <Menu.Item
                header
                className="borderless"
                onClick={() => setOpeness(!isOpen)}
              >
                <Button icon>
                  <Icon name="bars" />
                </Button>
              </Menu.Item>
            </Menu.Menu>
          </Menu>
        )}
      </div>
      {isMobile() && isOpen && (
        <Menu
          vertical
          size={"massive"}
          style={{
            margin: 0,
            width: "100%",
            borderRadius: 0,
            borderTop: 0,
          }}
        >
          <InternalContributorMenu>{children}</InternalContributorMenu>
        </Menu>
      )}
    </header>
  );
}

function InternalContributorMenu({
  children,
}: React.PropsWithChildren<IHeaderProps>) {
  const history = useHistory();
  return (
    <Menu.Menu>
      <Menu.Item
        header
        className="borderless"
        link
        onClick={() => history.push(ROUTES.contributorProfile.getPath())}
        active={isCurrent(ROUTES.contributorProfile, history.location)}
      >
        Profile
      </Menu.Item>
      <Menu.Item
        header
        className="borderless"
        link
        onClick={() => history.push(ROUTES.contributorEvents.getPath())}
        active={isCurrent(ROUTES.contributorEvents, history.location)}
      >
        My achievements
      </Menu.Item>
      {children}
    </Menu.Menu>
  );
}
