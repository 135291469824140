import AdminLayout from "./AdminLayout";
import React from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import FullEventFragment, { IEvent } from "../services/FullEventFragment";
import EventCard from "../EventCard";
import { eventAndFeedbackGraphqlClient } from "../index";
import { CardGroup } from "semantic-ui-react";
import { PlanOrUpdateEvent } from "../ContributorEvents";

export default function AdminValidateEvents() {
  const { loading, error, data, refetch } = useQuery<{
    eventsToValidate: IEvent[];
    validatedEvents: IEvent[];
  }>(
    gql`
      ${FullEventFragment}
      query Events {
        eventsToValidate {
          ...FullEvent
        }
        validatedEvents {
          ...FullEvent
        }
      }
    `,
    { client: eventAndFeedbackGraphqlClient }
  );

  const [validateEvent] = useMutation<{ createEvent: IEvent }>(
    gql`
      ${FullEventFragment}
      mutation ValidateEvent($id: ID!) {
        validateEvent(id: $id) {
          ...FullEvent
        }
      }
    `,
    { client: eventAndFeedbackGraphqlClient }
  );

  const [deleteEvent] = useMutation<{ createEvent: IEvent }>(
    gql`
      mutation DeleteEvent($id: ID!) {
        deleteEvent(id: $id)
      }
    `,
    { client: eventAndFeedbackGraphqlClient }
  );

  return (
    <AdminLayout>
      <h2>Events to validate</h2>
      {loading && <>Loading...</>}
      {error && <>Error, try to refresh the page in few minutes</>}
      {!loading && data && (
        <CardGroup>
          {data.eventsToValidate.map((event) => (
            <EventCard
              withValidateButton
              withDeleteButton
              event={event}
              key={event._id}
              onDeleteButtonClick={() =>
                window.confirm("Do you really want to delete this event ?")
                  ? deleteEvent({ variables: { id: event._id } }).then(() =>
                      window.location.reload()
                    )
                  : ""
              }
              onValidateButtonClick={() =>
                validateEvent({ variables: { id: event._id } })
              }
              editButton={
                <PlanOrUpdateEvent
                  existingEvent={event}
                  onEventUpdated={(event) => {
                    refetch();
                  }}
                />
              }
            />
          ))}
        </CardGroup>
      )}
      <h2>Validated events</h2>
      {loading && <>Loading...</>}
      {error && <>Error, try to refresh the page in few minutes</>}
      {!loading && data && (
        <CardGroup>
          {data.validatedEvents.map((event) => (
            <EventCard
              event={event}
              key={event._id}
              withDeleteButton
              onDeleteButtonClick={() =>
                window.confirm("Do you really want to delete this event ?")
                  ? deleteEvent({ variables: { id: event._id } }).then(() =>
                      window.location.reload()
                    )
                  : ""
              }
              editButton={
                <PlanOrUpdateEvent
                  existingEvent={event}
                  onEventUpdated={(event) => {
                    refetch();
                  }}
                />
              }
            />
          ))}
        </CardGroup>
      )}
    </AdminLayout>
  );
}
