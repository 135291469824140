import React, { PropsWithChildren } from "react";
import Header, { ROUTES } from "../Header";
import { Sidebar, Menu, Container } from "semantic-ui-react";
import { useLocation } from "react-router";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

export default function AdminLayout({children}: PropsWithChildren<{}>) {
    const location = useLocation();
    const {data} = useQuery<{isAdmin: boolean}>(
        gql`
        query IsAdmin{
            isAdmin
        }
        `
    )
    
    return <div className='ui grid' style={{ margin: 0 }}>
        {data?.isAdmin && <div className='ui column grey' style={{ minHeight: '100vh', paddingTop: '64.92px', paddingLeft: 0, paddingBottom: 0 }}>
            <Header />

            <Sidebar.Pushable>
                <Sidebar
                    as={Menu}
                    animation='overlay'
                    //onHide={() => setVisible(false)}
                    vertical
                    visible={true}
                >
                    {Object.entries(ROUTES).filter(([route]) => route.startsWith('admin'))
                        .map(([route, routeDefinition]) => (
                            <Menu.Item key={routeDefinition.path} as='a' href={routeDefinition.getPath()} active={location.pathname === routeDefinition.getPath()}>
                                {route.replace('admin', '').replace(/([A-Z])/g, ' $1')}
                            </Menu.Item>
                        ))}
                </Sidebar>

                <Sidebar.Pusher>
                    <Container text style={{ color: 'black', paddingTop: '2em', paddingBottom: '2em' }}>
                        {children}
                    </Container>
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        </div>}
    </div>;
}