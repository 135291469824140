import { IEvent } from "./services/FullEventFragment";
import { Card, Popup, Label, Icon, Button } from "semantic-ui-react";
import React, { useEffect } from "react";
import ReadOnlyCopyInput from "./ReadOnlyCopyInput";
import { ROUTES } from "./Header";
import { useHistory } from "react-router-dom";
import { useFetchContributors } from "./useFetchContributors";

export const humanReadableLocationType = (locationType: string) => {
  switch (locationType) {
    case "IN_PERSON":
      return "Live";
    case "INSTRUCTOR_LED_ONLINE":
      return "Trainer-led online";
    case "FACILITATED_ONLINE":
      return "Facilitated online";
    case "SELF_PACE":
      return "Self pace";
    case "BLENDED":
      return "Blended";
    default:
      return "Trainer-led online";
  }
};

function TrainerFirstAndLastName({
  trainer,
}: {
  trainer?: { firstName: string; lastName: string };
}) {
  return <>{trainer ? `${trainer.firstName} ${trainer.lastName}` : ""}</>;
}

export default function EventCard({
  event,
  withValidateButton,
  withDeleteButton,
  editButton,
  onDeleteButtonClick,
  onValidateButtonClick,
}: {
  event: IEvent;
  withDeleteButton?: boolean;
  withValidateButton?: boolean;
  editButton?: JSX.Element;
  onDeleteButtonClick?: () => void;
  onValidateButtonClick?: () => void;
}) {
  const history = useHistory();

  const [fetchContributors, contributors] = useFetchContributors();

  useEffect(() => {
    if (event) {
      fetchContributors({
        variables: {
          ids: [event.leadTrainer, ...(event.coLeadTrainers || [])],
        },
      });
    }
  }, [event, fetchContributors]);

  return (
    <Card>
      {event.isValidatedByAdmin && (
        <Popup
          content="Your event is validated"
          trigger={
            <Label color="green" size="large" corner>
              <Icon name="check" />
            </Label>
          }
        />
      )}
      <Card.Content>
        <Card.Header>
          {event.type}
          {event.type === "TRAINING" && event.trainingTypes
            ? " - " + event.trainingTypes.map((type) => type.name).join(", ")
            : ""}
        </Card.Header>
        <Card.Meta>
          {event.startDate}{" "}
          {event.endDate !== event.startDate && <>- {event.endDate}</>}
          {event.startTime && (
            <>
              <br />
              {event.startTime} - {event.endTime}
            </>
          )}
        </Card.Meta>
        <Card.Description>
          {event.audience && (
            <p style={{ textAlign: "center" }}>
              {humanReadableLocationType(event.location.type)} -{" "}
              {event.audience} <br />
            </p>
          )}
          <p>
            Trainers :{" "}
            {[event.leadTrainer, ...(event.coLeadTrainers || [])]
              .map<React.ReactNode>((trainerId) => (
                <TrainerFirstAndLastName
                  key={trainerId}
                  trainer={contributors[trainerId]}
                />
              ))
              .reduce((prev, curr) => [prev, <>, </>, curr])}
          </p>
          {event.location.locationName && (
            <>
              {event.location.locationName}
              <br />
            </>
          )}
          {(event.location.streetNumber || event.location.streetName) && (
            <>
              {event.location.streetNumber} {event.location.streetName}
              <br />
            </>
          )}
          {event.location.addtional && (
            <>
              {event.location.addtional}
              <br />
            </>
          )}
          {(event.location.city || event.location.zipCode) && (
            <>
              {event.location.city} {event.location.zipCode}
              <br />
            </>
          )}
          {event.location.country && <>{event.location.country}</>}
          {(event.location.onlineLocationUrl ||
            event.location.onlineLocationDescription) &&
            event.location.country && (
              <>
                <br />
                <br />
              </>
            )}
          {event.location.onlineLocationUrl && (
            <>
              <a href={event.location.onlineLocationUrl} target="blank">
                {event.location.onlineLocationUrl}
              </a>{" "}
              <br />
            </>
          )}
          {event.location.onlineLocationDescription && (
            <>{event.location.onlineLocationDescription}</>
          )}
          <br />
          {event.language && <>Language: {event.language?.name}</>}
        </Card.Description>
      </Card.Content>
      {event.feedbackLinkId && (
        <Card.Content extra>
          Feedback url
          <ReadOnlyCopyInput
            value={
              window.location.origin + "/feedbacks/" + event.feedbackLinkId
            }
          />
          <br />
          <br />
          <Button
            onClick={() =>
              history.push(ROUTES.eventFeedback.getPath(event._id))
            }
          >
            View feedbacks
          </Button>
        </Card.Content>
      )}
      {withValidateButton && !event.isValidatedByAdmin && (
        <Card.Content extra>
          <Button color="green" onClick={onValidateButtonClick}>
            Validate
          </Button>
        </Card.Content>
      )}
      {withDeleteButton && (
        <Card.Content extra>
          <Button color="red" onClick={onDeleteButtonClick}>
            Delete
          </Button>
        </Card.Content>
      )}
      {editButton && <Card.Content extra>{editButton}</Card.Content>}
    </Card>
  );
}
