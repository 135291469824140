import React from "react";
import { useParams, useLocation, useHistory } from "react-router";
import { Container, Icon, Button, Divider } from "semantic-ui-react";
import Header, { ROUTES } from "./Header";
import { isMobile } from "./services/utils";

export default function Login() {
    const { type } = useParams<{ type: 'contributor' | 'customer' }>();
    const location = useLocation();
    const history = useHistory();

    if (location.search) {
        const queryParams = new URLSearchParams(location.search);
        if (queryParams.has('token')) {
            const token = queryParams.get('token');
            if (token) {
                localStorage.setItem('token', token);
                if (type === 'contributor') {
                    history.push(ROUTES.contributorProfile.getPath())
                }
            }
        }
    }

    return (
        <div className='ui grid' style={{ margin: 0 }}>
            <div className='ui column red' style={{ height: '100vh', paddingTop: isMobile() ? undefined : '10em', paddingLeft: isMobile() ? undefined : '2em' }}>
                <Header />

                <Container style={isMobile() ? {marginTop: "3em"}: undefined} text>
                    <h2 className='ui header' style={{ color: 'white' }}>Log in or Sign in
            {type === 'contributor' && <div className='ui sub header' style={{ color: 'white' }}>To become a contributor</div>}
                        {type === 'customer' && <div className='ui sub header' style={{ color: 'white' }}>To become a customer</div>}
                    </h2>

                    <Divider hidden />
                    <Button size="huge" secondary as={'a'} href='/api/auth/linkedin'>
                        Log in or sign in with Linkedin
                    </Button>
                    <Divider hidden />
                    <Divider hidden />

                    <p>We would like to get access to your profile info.
                        <sup>
                            <a href="https://www.linkedin.com/help/linkedin/answer/1207?trk=uas-oauth-authorize&lang=en" target='blank' style={{ color: 'white' }}>
                                <Icon style={{ cursor: 'pointer' }} name='info circle' />
                            </a>
                        </sup>
                    </p>

                    <p>We don't post anything without your permission.<br />
                        Read Our Terms and Conditions before signing up.</p>
                </Container>
            </div>
        </div>

    )
}