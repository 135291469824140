import Avatar from "./Avatar";
import React, { useState, useRef, ChangeEvent } from "react";
import { Card, Label } from "semantic-ui-react";

export default function EditableAvatar({ size, profileUrl, halfBody }: { size: 'avatar' | 'big', profileUrl: string, halfBody?: boolean }) {
    const inputRef = useRef<HTMLInputElement>(null);
    const [updatedPictureUrl, setUpdatedPictureUrl] = useState();

    const selectFiles = () => {
        if (inputRef.current) {
            console.log(inputRef.current)
            inputRef.current.dispatchEvent(new MouseEvent('click', {
                view: window,
                bubbles: false,
                cancelable: true
            }))
        }
    }

    const uploadFile = (e: ChangeEvent<HTMLInputElement>) => {
        console.log(e.target.files)
        if (e.target.files && e.target.files.length === 1) {
            const token = localStorage.getItem('token')
            fetch(`/api/profile/picture${halfBody ? '?halfBody=true' : ''}`, {
                method: 'POST',
                headers: {
                    authorization: token ? `Bearer ${token}` : '',
                    'content-type': e.target.files[0].type
                },
                body: e.target.files[0]
            }).then(r => r.json()).then(response => setUpdatedPictureUrl(response.pictureUrl));
        }

    }

    return <Card>
    <Label icon='edit' corner='right' as='a' onClick={selectFiles}/>
    <input ref={inputRef} type='file' style={{ opacity: 0, height: 0 }} onChange={uploadFile} />
    <Avatar
        size={size}
        src={updatedPictureUrl || profileUrl}
    />
    <Card.Content>
        <Card.Header>{halfBody ? 'Half body' : 'Portrait'}</Card.Header>
    </Card.Content>
</Card>

}