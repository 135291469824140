import React, { useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { eventAndFeedbackGraphqlClient } from ".";
import { IFeedback, IRecommendation } from "./FeedbackForm";
import { useParams } from "react-router-dom";
import { isMobile } from "./services/utils";
import { ContributorHeader } from "./Header";
import {
  useLocalizedFeedbackQuestions,
  FeedbackQuestions,
} from "./admin/AdminFeedbackQuestions";
import { Rating, Segment } from "semantic-ui-react";
import FullEventFragment, { IEvent } from "./services/FullEventFragment";
import { useFetchContributors } from "./useFetchContributors";

export function EventFeedbackPage() {
  const { eventId } = useParams<{ eventId: string }>();
  return (
    <div className="ui grid" style={{ margin: 0 }}>
      <div
        className="ui column grey"
        style={{
          minHeight: "100vh",
          paddingTop: isMobile() ? undefined : "7em",
          paddingLeft: isMobile() ? undefined : "2em",
        }}
      >
        <ContributorHeader />
        <EventFeedback eventId={eventId} />
      </div>
    </div>
  );
}

export function EventFeedback({ eventId }: { eventId: string }) {
  const {
    loading: loadingFeedbackQuestions,
    error: errorLoadingFeedbackQuestions,
    feedbackQuestions,
  } = useLocalizedFeedbackQuestions();

  const { loading, error, data } = useQuery<{
    contributorFeedbacks: IFeedback[];
    eventWithId: IEvent;
  }>(
    gql`
        ${FullEventFragment}
        query ContributorFeedback {
          eventWithId(eventId: "${eventId}") {
            ...FullEvent
          }
          contributorFeedbacks(eventId: "${eventId}") {
            _id
            eventId
            leadTrainerRecommendation {
              grade
              positiveComment
              improveComment
            }
            coLeadTrainersRecommendation {
              coLeadTrainerId
              recommendation {
                grade
                positiveComment
                improveComment
              }
            }
            hostRecommendation {
              grade
              positiveComment
              improveComment
            }
            eventRecommendation {
              grade
              positiveComment
              improveComment
            }
            firstName
            lastName
            organization
            recommendationDate
            isFeedbackVisible
            leadTrainerHideImproveRecommendation
            coLeadTrainerHideImproveRecommendation
            hostHideImproveRecommendation
            showLastName
          }
        }
      `,
    { client: eventAndFeedbackGraphqlClient }
  );

  const [
    fetchLinkedContributors,
    normalizedContributors,
  ] = useFetchContributors();

  useEffect(() => {
    if (data?.eventWithId) {
      const contributorIds = [
        data.eventWithId.leadTrainer,
        ...(data.eventWithId.coLeadTrainers || []),
      ];
      fetchLinkedContributors({ variables: { ids: contributorIds } });
    }
  }, [data, fetchLinkedContributors]);

  return (
    <div style={{ color: "black" }}>
      <h2>
        Event feedbacks{" "}
        {data?.contributorFeedbacks && (
          <>({data?.contributorFeedbacks.length})</>
        )}
      </h2>

      {(loading || loadingFeedbackQuestions) && <>Loading...</>}
      {(error || errorLoadingFeedbackQuestions) && (
        <>
          An error occured while loading your event feedback, please retry in
          few minutes.
        </>
      )}
      {data?.contributorFeedbacks &&
        data?.eventWithId &&
        normalizedContributors &&
        data.contributorFeedbacks.map((feedback) => {
          return (
            <Segment key={feedback._id}>
              <h3>
                {feedback.firstName} feedback on{" "}
                {new Date(feedback.recommendationDate).toLocaleString()}
              </h3>

              <DisplayRecommendation
                recommendation={feedback.leadTrainerRecommendation}
                feedbackQuestions={feedbackQuestions}
                label={`Review of ${
                  normalizedContributors[data.eventWithId.leadTrainer]
                    ?.firstName
                }`}
              />

              {feedback.coLeadTrainersRecommendation.map(
                (coleadRecommendation) => (
                  <DisplayRecommendation
                    key={coleadRecommendation.coLeadTrainerId}
                    recommendation={coleadRecommendation.recommendation}
                    feedbackQuestions={feedbackQuestions}
                    label={`Review of ${
                      normalizedContributors[
                        coleadRecommendation.coLeadTrainerId
                      ]?.firstName
                    }`}
                  />
                )
              )}

              <DisplayRecommendation
                recommendation={feedback.eventRecommendation}
                feedbackQuestions={feedbackQuestions}
                label={`Review of the event/workshop`}
              />

              <DisplayRecommendation
                recommendation={feedback.hostRecommendation}
                feedbackQuestions={feedbackQuestions}
                label={`Review of the host/organizer`}
              />
            </Segment>
          );
        })}
    </div>
  );
}

const DisplayRecommendation = ({
  recommendation,
  feedbackQuestions,
  label,
}: {
  label: string;
  feedbackQuestions: FeedbackQuestions;
  recommendation: IRecommendation;
}) => (
  <>
    <h4>{label}</h4>

    <Rating disabled maxRating={5} defaultRating={recommendation.grade} />

    <h5>{feedbackQuestions.positiveCommentDescription}</h5>
    <p>{recommendation.positiveComment}</p>

    <h5>{feedbackQuestions.improveCommentDescription}</h5>
    <p>{recommendation.improveComment}</p>
  </>
);
