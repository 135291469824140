import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { isMobile } from "./services/utils";
import Header from "./Header";
import FeedbackForm, { IFeedback } from "./FeedbackForm";
import {
  useLocalizedFeedbackQuestions,
} from "./admin/AdminFeedbackQuestions";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { eventAndFeedbackGraphqlClient } from ".";
import FullEventFragment, { IEvent } from "./services/FullEventFragment";
import { SelectLanguage } from "./SelectLanguage";
import { Menu } from "semantic-ui-react";

export function GiveFeedbackPage() {
  const { eventFeedbackLinkId } = useParams<{ eventFeedbackLinkId: string }>();
  return (
    <div className="ui grid" style={{ margin: 0 }}>
      <div
        className="ui column grey"
        style={{
          minHeight: "100vh",
          paddingTop: isMobile() ? undefined : "7em",
          paddingLeft: isMobile() ? undefined : "2em",
        }}
      >
        <Header>
          <Menu.Item className="borderless">
            <SelectLanguage />
          </Menu.Item>
        </Header>
        <GiveFeedback feedbackLinkId={eventFeedbackLinkId} />
      </div>
    </div>
  );
}

export function GiveFeedback({ feedbackLinkId }: { feedbackLinkId: string }) {
  const {
    loading: loadingFeedbackQuestions,
    error: errorFeedbackQuestions,
    feedbackQuestions,
  } = useLocalizedFeedbackQuestions();
  const { loading, error, data } = useQuery<{
    event: IEvent;
  }>(
    gql`
            ${FullEventFragment}
          query Feedback {
            event(eventFeedbackLinkId: "${feedbackLinkId}") {
                ...FullEvent
            }
          }
        `,
    { client: eventAndFeedbackGraphqlClient }
  );

  const [
    fetchLinkedContributors,
    {
      loading: isFetchingContributors,
      error: isFetchingContributorsInError,
      data: contributors,
    },
  ] = useLazyQuery<{
    contributorsWithId: {
      _id: string;
      firstName: string;
      lastName: string;
    }[];
  }>(gql`
    query Contributors($ids: [ID]) {
      contributorsWithId(ids: $ids) {
        _id
        firstName
        lastName
      }
    }
  `);

  let normalizedContributors = {};
  if (contributors && contributors.contributorsWithId) {
    normalizedContributors = contributors.contributorsWithId.reduce(
      (previous, current) => ({
        ...previous,
        [current._id]: {
          firstName: current.firstName,
          lastName: current.lastName,
        },
      }),
      {}
    );
  }

  useEffect(() => {
    if (data?.event) {
      const contributorIds = [
        data.event.leadTrainer,
        ...(data.event.coLeadTrainers || []),
      ];
      fetchLinkedContributors({ variables: { ids: contributorIds } });
    }
  }, [data, fetchLinkedContributors]);

  const [submittedFeedback, setSubmittedFeedback] = useState<IFeedback>();

  return (
    <div style={{ color: "black" }}>
      {(loading || loadingFeedbackQuestions || isFetchingContributors) && (
        <>Loading...</>
      )}
      {(error || errorFeedbackQuestions || isFetchingContributorsInError) && (
        <>An error occured, please retry in few minutes.</>
      )}
      {feedbackQuestions &&
        data?.event &&
        normalizedContributors &&
        !submittedFeedback && (
          <FeedbackForm
            event={data.event}
            contributors={normalizedContributors}
            feedbackQuestions={feedbackQuestions}
            onFeedbackSubmitted={(feedback) => setSubmittedFeedback(feedback)}
          />
        )}
      {feedbackQuestions && submittedFeedback && (
        <>
          <h2>{feedbackQuestions.feedbackTitle}</h2>
          <h3>{feedbackQuestions.thankYouForFeedback}</h3>

          <h3>{feedbackQuestions.youWillReceiveAMailForYourFeedback}</h3>
        </>
      )}
    </div>
  );
}
