import { Message } from "semantic-ui-react";
import { useState } from "react";
import React from "react";

export default function CookieBanner() {
    const [isVisible, setIsVisible] = useState(true);
    const toggle = () => { setIsVisible(!isVisible)};
    if(!isVisible) return <></>;
    return (
        <Message
            floating={true}
            style={{position: 'fixed', bottom: 0}}
            onDismiss={toggle}
            header={'This website also uses cookies'}
            content='
            Some are used for statistical purposes and others are set up by third party services. By continuing browsing this website, you accept the use of cookies.'
        >

        </Message>
    )
}