import React from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Input, Card, Divider, Button, Placeholder, Label } from "semantic-ui-react";
import Avatar from "./Avatar";

export default function SponsorshipForm({ profile }: { profile: any }) {
    const { loading: loadingSponsorRequests, data: retrievedData } = useQuery(
        gql`
            query SponsorRequests {
                sponsorRequests: findSponsorRequests(id: "${profile._id}") {
                    isValidated
                    candidate {
                        _id
                        firstName
                        lastName
                        profilePictureUrl
                    }
                }
            }
        `
    )

    const [searchSponsor, { loading, data }] = useLazyQuery(
        gql`
            query SearchSponsor($lastName: String) {
                sponsors(lastName: $lastName) {
                    _id
                    firstName
                    lastName
                    profilePictureUrl
                }
            }
        `
    )

    const [requestSponsorship] = useMutation(
        gql`
            mutation RequestSponsorship($id: ID!, $targetId: ID!) {
                askSponsorship(contributorToBeSponsoredId: $id, sponsorCandidateId: $targetId)
            }
        `
    )

    return <div>
        {loadingSponsorRequests && <Placeholder />}
        {retrievedData && retrievedData.sponsorRequests && retrievedData.sponsorRequests.length > 0 && <>
            <b>You asked their sponsorship</b>
            <Divider hidden />
            {(retrievedData?.sponsorRequests || []).length > 0 && <div>
                {retrievedData.sponsorRequests.map((sponsorRequest: any) => (
                    <Card key={sponsorRequest.candidate._id}>
                        <Avatar src={sponsorRequest.candidate.profilePictureUrl} size='big' style={{ textAlign: 'center' }} />
                        <Card.Content>
                            <Card.Header>
                                {sponsorRequest.isValidated && <Label empty circular color='green'></Label>}
                                {!sponsorRequest.isValidated && <Label empty circular color='yellow'></Label>}{' '}
                                {sponsorRequest.candidate.firstName} {sponsorRequest.candidate.lastName}</Card.Header>
                        </Card.Content>
                    </Card>
                ))}
            </div>}
            <Divider hidden />
        </>}
        <b>Search a contributor by it's last name </b>
        <Input
            icon='search'
            loading={loading}
            transparent
            onChange={(e) => searchSponsor({ variables: { lastName: e.target.value } })}
            placeholder='Search...' />
        <Divider hidden />
        {((data && data.sponsors) || []).length > 0 && <div>
            {((data && data.sponsors) || []).map((contributors: any) => {
                return <Card key={contributors._id}>
                    <Avatar src={contributors.profilePictureUrl} size='big' style={{ textAlign: 'center' }} />
                    <Card.Content>
                        <Card.Header>{contributors.firstName} {contributors.lastName}</Card.Header>
                    </Card.Content>
                    <Card.Content extra>
                        <Button fluid onClick={() => requestSponsorship({ variables: { id: profile._id, targetId: contributors._id } })}>Ask for sponsorship</Button>
                    </Card.Content>
                </Card>
            })}
        </div>}
    </div>
}