import React from "react";
import AdminFormValues from "./AdminFormValue";


const formValuePlural = 'currencies'
const formValueSingular = 'Currency'

export default function AdminCurrencies() {
    return <AdminFormValues formValuePlural={formValuePlural} formValueSingular={formValueSingular} />
}

