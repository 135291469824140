import { Card, Label, Dropdown, Rating, Form, Input, TextArea, Message, Button } from "semantic-ui-react";
import React, { useState, useReducer, Fragment } from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { saveAs } from 'file-saver';

export interface ICompetency {
    _id?: string;
    name?: string;
    autoEvaluation?: number;
    autoEvaluationExplanation?: string;
    startingYear?: number;
    justificationUrls?: string[];
    justificationFiles?: string[];
}

const reducer = (state: ICompetency, action: Partial<ICompetency>) => {
    return { ...state, ...action };
}

export default function ContributorCompetency({ competency, onDelete, isNew, saveCompetency }: { competency?: ICompetency, saveCompetency: (competency: ICompetency) => void, onDelete?: (competencyId: string) => void, isNew: boolean }) {
    const [editMode, setEditMode] = useState(false);
    const [newUrl, setNewUrl] = useState();
    const [newCertificate, setNewCertificate] = useState();

    const [editedCompetency, dispatch] = useReducer(reducer, competency || {});
    const setEditedCompetency = (key: string, value: any) => {
        dispatch({ [key]: value });
    }

    const setUrl = (index: number, value: string) => {
        (editedCompetency.justificationUrls || []).splice(index, 1, value);
        dispatch({ justificationUrls: editedCompetency.justificationUrls });
    }

    const removeUrl = (index: number) => {
        (editedCompetency.justificationUrls || []).splice(index, 1);
        dispatch({ justificationUrls: editedCompetency.justificationUrls });
    }

    const addUrl = (url: string) => {
        dispatch({ justificationUrls: [...(editedCompetency.justificationUrls || []), url] })
        setNewUrl('');
    }

    const removeCertificate = (index: number) => {
        (editedCompetency.justificationFiles || []).splice(index, 1);
        dispatch({ justificationFiles: editedCompetency.justificationFiles });
    }

    const setCertificate = (index: number, certificate: FileList | null) => {
        if (certificate && certificate.length === 1) {
            const token = localStorage.getItem('token')
            fetch('/api/upload', {
                method: 'POST',
                headers: {
                    authorization: token ? `Bearer ${token}` : '',
                    'content-type': certificate[0].type
                },
                body: certificate[0]
            }).then(r => r.json()).then(r => {
                (editedCompetency.justificationFiles || []).splice(index, 1, r.uploadId);
                dispatch({ justificationFiles: editedCompetency.justificationFiles})
            });
        }
    }

    const addCertificate = (certificate: FileList | null) => {
        setNewCertificate(certificate);
        if (certificate && certificate.length === 1) {
            const token = localStorage.getItem('token')
            fetch('/api/upload', {
                method: 'POST',
                headers: {
                    authorization: token ? `Bearer ${token}` : '',
                    'content-type': certificate[0].type
                },
                body: certificate[0]
            }).then(r => r.json()).then(r => dispatch({ justificationFiles: [...(editedCompetency.justificationFiles || []), r.uploadId] }));
        }
    }
    
    const downloadCertificate = (certifId: string) => {
        const token = localStorage.getItem('token')
        fetch('/api/upload/' + certifId, {
            method: 'GET',
            headers: {
                authorization: token ? `Bearer ${token}` : ''
            },
        }).then(r => r.blob()).then(r => saveAs(r, 'certificate.pdf'));
    }

    const { data: competencyList, error } = useQuery(
        gql`
        query Competencies {
            competencies {
                _id
                name
            }
        }
        `
    )

    const competenciesOptions: { key: string, text: string, value: string }[] = (competencyList?.competencies || []).map((competency: any) => {
        return {
            key: competency._id,
            text: competency.name,
            value: competency._id
        }
    })

    const editANewCompetency = (competencyId: any) => {
        setEditMode(true);
        setEditedCompetency('name', competenciesOptions.find(competency => competency.key === competencyId)?.text)
        setEditedCompetency('_id', competencyId)

    }

    const cancelOrStartEdition = () => {
        setEditMode(!editMode);
    }

    const validateAndSave = () => {
        saveCompetency(editedCompetency);
        setEditMode(false);
    }

    if (competencyList && isNew && !editMode) {
        return <Card>
            <Card.Content>
                <Card.Header>Select a new competency</Card.Header>
                <Card.Description>
                    <Dropdown placeholder='Select a competency' fluid search selection options={competenciesOptions} onChange={(__, newState) => editANewCompetency(newState.value)} />
                </Card.Description>
            </Card.Content>
        </Card>
    }


    return (
        <Card>
            <Card.Content>
                <Label as='a' corner='right' icon={editMode ? 'cancel' : 'edit'} color={editMode ? 'red' : undefined} onClick={cancelOrStartEdition} />
                <Card.Header>{editedCompetency.name || competency?.name}</Card.Header>
                <Card.Meta>
                    <Rating
                        rating={editedCompetency.autoEvaluation || (competency?.autoEvaluation)}
                        maxRating={5}
                        disabled={!editMode}
                        onRate={(__, newRating) => setEditedCompetency('autoEvaluation', newRating.rating)}
                    />
                    {!editMode &&
                        <span>- Since {competency?.startingYear}</span>
                    }
                </Card.Meta>
                <Card.Description>
                    {editMode && <Form>
                        <Input
                            labelPosition='right'
                            fluid
                            type='number'
                            value={editedCompetency.startingYear || (competency && competency.startingYear)}
                            min={0}
                            placeholder='Year'
                            onChange={(__, newState) => setEditedCompetency('startingYear', newState.value)}
                        >
                            <Label basic>Since</Label>
                            <input />
                        </Input>
                        <TextArea fluid onChange={(__, newState) => setEditedCompetency('autoEvaluationExplanation', newState.value)}>
                            {editedCompetency.autoEvaluationExplanation || (competency && competency.autoEvaluationExplanation)}
                        </TextArea>
                        Upload a certificate
                        <Input fluid type='file' onChange={(e) => addCertificate(e.target.files)} action>
                            <input />
                            <Button onClick={() => addCertificate(newCertificate)}>Add</Button></Input>
                        {(editedCompetency.justificationFiles || competency?.justificationFiles || []).map((certificate: string, index: number) => {
                            return <div key={index}>
                                <Input fluid type='file' onChange={(e) => setCertificate(index, e.target.files)} action>
                                    <input />
                                    <Button onClick={() => downloadCertificate(certificate)}>Download</Button>
                                    <Button color='red' onClick={() => removeCertificate(index)}>Remove</Button>
                                </Input>
                            </div>;
                        })}
                        Copy and paste an url with a certificate
                        <Input fluid value={newUrl} onChange={(__, newState) => setNewUrl(newState.value)}>
                            <input />
                            <Button onClick={() => addUrl(newUrl)}>Add</Button>
                        </Input>
                        {(editedCompetency.justificationUrls || competency?.justificationUrls || []).map((url: string, index: number) => {
                            return <div key={index}>
                                <Input fluid value={url} onChange={(__, newState) => setUrl(index, newState.value)}>
                                    <input />
                                    <Button color='red' onClick={() => removeUrl(index)}>Remove</Button>
                                </Input>
                            </div>;
                        })}
                    </Form>}
                    {!editMode && <>
                        {(competency?.autoEvaluationExplanation || '').split('/n').map((line: string) => <p key={line}>{line}</p>)}
                        {(editedCompetency.justificationFiles || competency?.justificationFiles || []).length > 0 && <>
                            Certificates <br/>
                            {(editedCompetency.justificationFiles || competency?.justificationFiles || []).map(certificate => {
                            return <Fragment key={certificate}><Button onClick={() => downloadCertificate(certificate)}>Download</Button><br/></Fragment>;
                            })}
                        </>}
                        {(editedCompetency.justificationUrls || competency?.justificationUrls || []).length > 0 && <>
                            Urls with a certificate <br/>
                            {(editedCompetency.justificationUrls || competency?.justificationUrls || []).map(url => {
                            return <Fragment key={url}><a target='about:blank' href={url}>{url}</a><br/></Fragment>;
                            })}
                        </>}
                    </>}
                </Card.Description>
            </Card.Content>
            {editMode && <Card.Content extra>
                {error && <Message
                    warning
                    content={error}
                />}
                <div className='ui two buttons'>
                    <Button basic color='green' onClick={validateAndSave}>
                        Save
                        </Button>
                    <Button basic color='red' onClick={() => !(competency && competency._id) || !onDelete ? cancelOrStartEdition() : onDelete(competency._id)}>
                        Delete
                        </Button>
                </div>
            </Card.Content>}
        </Card>

    );
}