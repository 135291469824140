import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import 'semantic-ui-less/semantic.less'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { ROUTES } from './Header';
import { ApolloProvider } from '@apollo/react-hooks';
import ApolloClient, { Operation } from 'apollo-boost';
import { ErrorResponse } from 'apollo-link-error';

const request = (operation: Operation) => {
  // Strip __typename from variables
  if (operation.variables) {
    const omitTypename = (key:string, value: any) => (key === '__typename' ? undefined : value);
    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
  }
  // Add token
  const token = localStorage.getItem('token');
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : ''
    }
  });
};
const onError = (e: ErrorResponse) => {
  if (e.graphQLErrors && e.graphQLErrors[0]?.extensions?.code === "UNAUTHENTICATED") {
    const redirectUri = Object.values(ROUTES).find((routeProperties) => {
      if (routeProperties.path === '/') return false;
      const regex = routeProperties.path.replace(/:[^/]+/, '[^/]+').replace("/", "/");
      return window.location.pathname.match(regex);
    })
    if (redirectUri && redirectUri.redirectToWhenLogggedOut) {
      window.location.pathname = redirectUri.redirectToWhenLogggedOut;
    }
  }
}

export const mainGraphqlClient = new ApolloClient<object>({
  uri: '/api/graphql',
  request,
  onError
});

export const eventAndFeedbackGraphqlClient = new ApolloClient<object>({
  uri: '/api/feedback/graphql',
  request,
  onError
});

const Routes = function() {
    return <Switch>
    {Object.entries(ROUTES).map(([route, routeProperties]) => {
        return <Route key={route} exact={routeProperties.isExact} path={routeProperties.path}>
          <routeProperties.component />
        </Route>;
    })}
    <Redirect from='/admin' to={ROUTES.adminLanguages.getPath()} />
</Switch>
} 

ReactDOM.render(
    <ApolloProvider client={mainGraphqlClient}>
    <Router>
        <Routes />
    </Router>
    </ApolloProvider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
