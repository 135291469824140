import gql from "graphql-tag";
import FullContributorFragment from "./FullContributorFragment";

const UpdateContributorMutation = gql`
${FullContributorFragment}
mutation UpdateMe($id: ID!, $newProfile: ContributorInput) {
  updateContributorProfile(id: $id, newProfile: $newProfile) {
    ...FullContributor
  }
}
`

export default UpdateContributorMutation;