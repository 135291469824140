import React from "react";
import AdminFormValues from "./AdminFormValue";


const formValuePlural = 'competencies'
const formValueSingular = 'Competency'

export default function AdminCompetencies() {
    return <AdminFormValues formValuePlural={formValuePlural} formValueSingular={formValueSingular} />
}

