import gql from 'graphql-tag';

const FullContributorFragment = gql`
fragment FullContributor on Contributor {
  _id
  firstName
  lastName
  profilePictureUrl
  halfBodyPictureUrl
  socialLinks {
    linkedIn
    facebook
    instagram
    youtube
    tweeter
    professionalWebsite
  }
  geographicAvailability
  languages {
    _id
    name
  }
  contactInformation {
    address {
      streetNumber
      streetName
      addtional
      zipCode
      city
      country
    }
    secondaryAddress {
      city
      country
    }
    mobilePhone
    otherPhone
    email
    otherEmail
  }
  employmentInformation {
    isEmployed
    company
  }
  invoicingInformation {
    organizationName
    email
    address {
      streetNumber
      streetName
      addtional
      zipCode
      city
      country
    }
    vatNumber
  }
  types
  trainerInformation {
    showOnAcademyWebsite
    showOnAcademyWebsiteValidated
    description {
      locale
      localizedValue
    }
    startingYear
    isDthinkingPractitioner
    dthinkingPractitioner {
      category {
        _id
        name
      }
      isCategoryValidated
    }
    isDthinkingCatalyst
    dthinkingCatalyst {
      category {
        _id
        name
      }
      isCategoryValidated
    }
    isDthinkingArtist
    dthinkingArtist {
      category {
        _id
        name
      }
      isCategoryValidated
    }
  }
  consultantInformation {
    showOnConsultingWebsite
    showOnConsultingWebsiteValidated
    valueProposition
    isFreelancable
    freelancableConditions
    isFreelancer
    freelancerStatus
    isPartOfFreelancerGroup
    freelancerGroupName
    freelancerGroupWebsite
    contractType
    freelancerAssignement
    workingDaysPerWeek
    dailyRate {
      currency {
        _id
        name
      }
      shortTerm
      longTerm
    }
  }
  competencies {
    _id
    name
    autoEvaluation
    autoEvaluationExplanation
    startingYear
    justificationUrls
    justificationFiles
  }
}
`;

export default FullContributorFragment;