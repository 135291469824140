import React, { FocusEvent, useState, useEffect } from "react";
import { Input, Popup, Button } from "semantic-ui-react";

export default function ReadOnlyCopyInput({value}: {value: string}) {
    const [isJustCopied, setIsJustCopied] = useState(false);
    
    useEffect(() => {
        if (isJustCopied) {
            const timeout = setTimeout(() => {
                setIsJustCopied(false);
            }, 500)
            return () => clearTimeout(timeout);
        }
    }, [isJustCopied]);

    const copyValueToClipboard = () => {
        if (!navigator || !navigator.clipboard) {
            return;
        }
        navigator.clipboard.writeText(value).then(function() {
            setIsJustCopied(true);
        });
    }

    return <Input
    onFocus={(e: FocusEvent<HTMLInputElement>) => e.target.select()}
    defaultValue={value}
    action={
      <Popup content='Copy feedback url' trigger={<Button icon={isJustCopied ? 'check' : 'copy'} onClick={() => copyValueToClipboard()} />} />
    }
  />
}