import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { Dropdown } from "semantic-ui-react";

type Profile = {
  trainerInformation: {
    isDthinkingPractitioner: boolean;
    dthinkingPractitioner: {
      category: {
        _id: string;
        name: string;
      };
      isCategoryValidated: boolean;
    }[];
    isDthinkingCatalyst: boolean;
    dthinkingCatalyst: {
      category: {
        _id: string;
        name: string;
      };
      isCategoryValidated: boolean;
    }[];
    isDthinkingArtist: boolean;
    dthinkingArtist: {
      category: {
        _id: string;
        name: string;
      };
      isCategoryValidated: boolean;
    }[];
  };
};

type Training = {
  _id: string;
  name: string;
  category: "PRACTITIONER" | "CATALYST" | "ARTIST";
};

export default function SelectValidatedContributorTrainings({
  contributorId,
  multiple,
  values,
  onChange,
}: {
  contributorId: string;
  multiple?: boolean;
  values: Training[];
  onChange: (newlySelectedTrainings: Training[]) => void;
}) {
  const {
    loading: isLoading,
    data: { profile } = { profile: undefined },
  } = useQuery<{
    profile: Profile;
  }>(
    gql`
                query GetProfile {
                    profile: contributor(id: "${contributorId}") {
                        trainerInformation {
                            isDthinkingPractitioner
                            dthinkingPractitioner {
                            category {
                                _id
                                name
                            }
                            isCategoryValidated
                            }
                            isDthinkingCatalyst
                            dthinkingCatalyst {
                            category {
                                _id
                                name
                            }
                            isCategoryValidated
                            }
                            isDthinkingArtist
                            dthinkingArtist {
                            category {
                                _id
                                name
                            }
                            isCategoryValidated
                            }
                        }
                    }
                }
            `
  );

  if (isLoading) {
    return <>Loading...</>;
  }

  if (profile && profile.trainerInformation) {
    const validatedTrainings: Training[] = [];

    if (profile.trainerInformation.dthinkingPractitioner) {
      profile.trainerInformation.dthinkingPractitioner.forEach((category) => {
        if (category.isCategoryValidated) {
          // @ts-ignore
          delete category.category.__typename;
          validatedTrainings.push({
            ...category.category,
            category: "PRACTITIONER",
          });
        }
      });
    }

    if (profile.trainerInformation.dthinkingCatalyst) {
      profile.trainerInformation.dthinkingCatalyst.forEach((category) => {
        if (category.isCategoryValidated) {
          // @ts-ignore
          delete category.category.__typename;
          validatedTrainings.push({
            ...category.category,
            category: "CATALYST",
          });
        }
      });
    }

    if (profile.trainerInformation.dthinkingArtist) {
      profile.trainerInformation.dthinkingArtist.forEach((category) => {
        if (category.isCategoryValidated) {
          // @ts-ignore
          delete category.category.__typename;
          validatedTrainings.push({ ...category.category, category: "ARTIST" });
        }
      });
    }

    const options = validatedTrainings.map(mapToOption);

    const handleValueChange = (newValue: string | string[]) => {
      if (Array.isArray(newValue)) {
        onChange(
          newValue.map(
            (value) =>
              validatedTrainings.find(
                (training) => training._id === value
              ) as Training
          )
        );
      } else {
        onChange([
          validatedTrainings.find(
            (training) => training._id === newValue
          ) as Training,
        ]);
      }
    };

    return (
      <Dropdown
        fluid
        multiple={multiple}
        search
        selection
        options={options}
        value={
          multiple
            ? values.map((training) => training._id)
            : values && values[0]
            ? values[0]._id
            : undefined
        }
        onChange={(_, { value }) =>
          handleValueChange(value as string[] | string)
        }
      />
    );
  }

  return <>The leader has no validated trainings</>;
}

const mapToOption = (training: Training) => {
  return {
    key: training._id,
    text: training.category + " - " + training.name,
    value: training._id,
  };
};
